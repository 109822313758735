import React, { useState } from 'react';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import '../css/AddCompany.css'
import { toast } from 'react-toastify';

const AddCompany = ({ toggleComponent }) => {
  const [companyName, setCompanyName] = useState('');
  const [gstNo, setGstNo] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState('');
  const [logo, setLogo] = useState('');
  const [banner, setBanner] = useState('');
  const [terms, setTerms] = useState([{ id: 1, text: '' }]); // Array for terms
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [bannerUrl, setBannerUrl] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [isUploaded, setIsUploaded] = useState(false); // To track if an image was successfully uploaded
  const [addressLine1, setAddressLine1] = useState('');
  const [addressLine2, setAddressLine2] = useState('');
  const [addressLine3, setAddressLine3] = useState('');

  const uploadImage = async (file) => {
    setIsUploading(true); // Start loading state
    const formData = new FormData();
    formData.append('image', file);
    const token = localStorage.getItem('token');

    try {
      const response = await axios.post('https://emkayindia.in/api/v1/image/upload', formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data'
        },
      });
      return response.data.url; // Return the uploaded image URL
    } catch (error) {
      console.error('Error uploading image:', error);
      toast.error('Error uploading image');
      return null;
    } finally {
      setIsUploading(false); // Stop loading state
    }
  };
  const handleImageChange = async (file) => {
    const uploadedUrl = await uploadImage(file);
    if (uploadedUrl) {
      setBannerUrl(uploadedUrl); // Set the image URL
      setIsUploaded(true); // Mark the image as successfully uploaded
    }
  };

  const handleImageChangeLogo = async (file) => {
    const uploadedUrl = await uploadImage(file);
    if (uploadedUrl) {
      setLogo(uploadedUrl); // Set the image URL
      setIsUploaded(true); // Mark the image as successfully uploaded
    }
  };

  const handleTermChange = (index, event) => {
    const updatedTerms = [...terms];
    updatedTerms[index].text = event.target.value;
    setTerms(updatedTerms);
  };

  const addTerm = () => {
    setTerms([...terms, { id: terms.length + 1, text: '' }]);
  };

  const removeTerm = (index) => {
    const updatedTerms = terms.filter((_, i) => i !== index);
    setTerms(updatedTerms);
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   try {
  //     const token = localStorage.getItem('token');
  //     const decodedToken = jwtDecode(token);

  //     const companyData = {
  //       companyName,
  //       gstNo,
  //       email,
  //       phone,
  //       address,
  //       logo,
  //       banner,
  //       createdBy: decodedToken.id,
  //       terms: {
  //         termsAndConditions: terms.map(term => term.text),
  //       },
  //     };

  //     const response = await axios.post('https://emkayindia.in/api/v1/company/create', companyData, {
  //       headers: {
  //         'Authorization': `Bearer ${token}`,
  //         'Content-Type': 'application/json',
  //       },
  //     });

  //     setSuccess('Company created successfully');
  //     console.log('Success:', response.data);

  //     setCompanyName('');
  //     setGstNo('');
  //     setEmail('');
  //     setPhone('');
  //     setAddress('');
  //     setLogo('');
  //     setBanner('');
  //     setTerms([{ id: 1, text: '' }]);
  //   } catch (err) {
  //     setError('Failed to create company');
  //     console.error('Error:', err);
  //   }
  // };
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Get token from localStorage
      const token = localStorage.getItem('token');
      const decodedToken = jwtDecode(token);

      // Prepare company data with terms
      const companyData = {
        companyName,
        gstNo,
        email,
        phone,
        address:addressLine1 + '0/' + addressLine2 + '0/' + addressLine3,
        logo: logo,
        banner: bannerUrl,
        createdBy: decodedToken.id,
        terms: {
          termsAndConditions: terms.map(term => term.text), // Array of terms
        },
      };

      console.log("Sending company data:", companyData);

      // Send POST request to create company
      const response = await axios.post(
        'https://emkayindia.in/api/v1/company/create',
        companyData,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      // Success handling
      setSuccess('Company created successfully');
      console.log('Success:', response.data);
      toast.success('Comapany Added');
      toggleComponent('AddCompany');

      // Clear form fields after success
      setCompanyName('');
      setGstNo('');
      setEmail('');
      setPhone('');
      setAddress('');
      setLogo('');
      setBanner('');
      setTerms([{ id: 1, text: '' }]);
    } catch (err) {
      // Handle different types of errors and log them
      if (err.response) {
        console.error('Response error:', err.response.data);
        setError(`Error: ${err.response.data.message || 'Failed to create company'}`);
      } else if (err.request) {
        console.error('Request error:', err.request);
        setError('Error: No response from the server.');
      } else {
        console.error('Error:', err.message);
        setError('Error: Failed to create company.');
      }
    }
  };


  return (
    <div className="add-company-form">
      <h2 className='text-black-700 text-xl font-bold'>Add Company</h2>
      <form onSubmit={handleSubmit} className='add-company-inner-form'>
        <div className='mt-4'>
          <div className="form-group-add-company">
            <label>Company Name:</label>
            <input
              type="text"
              className="input-field-add-company"
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
              required
            />
          </div>
          <div className="form-group-add-company">
            <label>GST No:</label>
            <input
              type="text"
              className="input-field-add-company"
              value={gstNo}
              onChange={(e) => setGstNo(e.target.value)}
              required
            />
          </div>


        </div>
        <div className='mt-4'>
          <div className="form-group-add-company">
            <label>Phone:</label>
            <input
              type="text"
              className="input-field-add-company"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              required
            />
          </div>
          <div className="form-group-add-company w-[100%]">
            <label className='w-40%'>Upload <p>Logo :</p></label>
            <input
              type="file"
              onChange={e => handleImageChangeLogo(e.target.files[0])}
              required // Require at least the first image field
              className="block w-[65%] mt-2 p-2 border border-gray-300 rounded-md"
            />
            {isUploading && <p className="text-blue-600">Uploading...</p>}
          </div>


        </div>
        <div className='mt-4'>
          <div className="form-group-add-company">
            <label>Email:</label>
            <input
              type="email"
              className="input-field-add-company"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="form-group-add-company">

            <div>
              <div className="flex items-center">
                <label>Upload <p>Banner:</p></label>
                <input
                  type="file"
                  onChange={e => handleImageChange(e.target.files[0])}
                  required // Require at least the first image field
                  className="block w-[65%] mt-2 p-2 border border-gray-300 rounded-md"
                />

              </div>
              {isUploading && <p className="text-blue-600">Uploading...</p>}
            </div>
          </div>

          <button type="submit" className="submit-button-add-product absolute top-4 right-24">Add Company</button>
          {error && <p style={{ color: 'red' }}>{error}</p>}
          {success && <p style={{ color: 'green' }}>{success}</p>}
        </div>

      </form>

      {/* Dynamic Terms and Conditions Section */}

      {/* <div className="flex flex-col space-y-2 mb-4">
        <label className="font-medium text-gray-700">Address:</label>
        <textarea
          className=" border border-gray-300 p-3 rounded-lg w-[90%] resize-none h-14 focus:outline-none "
          value={address}
          onChange={(e) => setAddress(e.target.value)}
          required
          rows="4" // Optional, to define the number of visible rows
        />
      </div> */}
       <div className="mb-6 flex w-[52%]">
        <label className="text-lg font-semibold mb-2 block">Address:</label>
        <div className="space-y-3 w-full max-w-lg">
          <input
            type="text"
            placeholder="Address Line 1"
            value={addressLine1}
            onChange={(e) => setAddressLine1(e.target.value)}
            required
            className="border border-gray-300 rounded-lg p-2 w-[200%] focus:outline-none focus:ring-2 focus:ring-blue-400"
          />
          <input
            type="text"
            placeholder="Address Line 2"
            value={addressLine2}
            onChange={(e) => setAddressLine2(e.target.value)}
            className="border border-gray-300 rounded-lg p-2 w-[200%]  focus:outline-none focus:ring-2 focus:ring-blue-400"
          />
          <input
            type="text"
            placeholder="Address Line 3"
            value={addressLine3}
            onChange={(e) => setAddressLine3(e.target.value)}
            className="border border-gray-300 rounded-lg p-2 w-[200%]  focus:outline-none focus:ring-2 focus:ring-blue-400"
          />
        </div>
      </div>
      <div></div>

      <div className="terms-container">
        <label>Terms and Conditions:</label>
        <div className='terms-sec'>
          {terms.map((term, index) => (
            <div key={term.id} className="term-item">
              <div className='input-remove'>
                <input
                  type="text"
                  className="input-field-terms"
                  value={term.text}
                  onChange={(e) => handleTermChange(index, e)}
                  placeholder={`Term #${index + 1}`}
                  required
                />
                {terms.length > 1 && (
                  <button
                    type="button"
                    className="remove-button"
                    onClick={() => removeTerm(index)}
                  >
                    Remove
                  </button>
                )}


              </div>

            </div>
          ))}
          <div>
            {
              terms.length <= 6 && <button type="button" className="add-button" onClick={addTerm}>
                Add Term
              </button>
            }
          </div>

        </div>



      </div>



    </div>
  );
};

export default AddCompany;

