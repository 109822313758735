import React, { useState, useEffect, useRef } from 'react';
import "./css/CreateQuatation.css";
import axios from 'axios';
import { toast } from 'react-toastify';
import A4Quotation from './a4-quotation';
import { blue } from '@mui/material/colors';
function useDebouncedEffect(callback, delay, dependencies) {
  useEffect(() => {
    const handler = setTimeout(() => callback(), delay);

    return () => {
      clearTimeout(handler); // Clear timeout on unmount or when dependencies change
    };
  }, [...dependencies]);
}

const CreateQuotation = ({ toggleComponent, selectedQuotationId, selectedCompany }) => {
  const initialProductRow = { serialNo: '', productId: '', name: '', hsn: '', brand: '', variant: '', quantity: '', price: '', discount: '', gst: '', total: '', searchTerm: '', suggestions: [] };
  const initialRows = Array.from({ length: 5 }, (_, index) => ({ ...initialProductRow, serialNo: index + 1 }));
  const [billingProducts, setBillingProducts] = useState(initialRows);  // Start with a single product row
  const [terms, setTerms] = useState([]);  // Terms & Conditions state
  const [clientSearchTerm, setClientSearchTerm] = useState('');
  const [clientSuggestions, setClientSuggestions] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [printData, setPrintData] = useState(null);
  const [billType, setBillType] = useState('PI');  // Invoice Type state
  const [brands, setBrands] = useState([]);  // Product Brands state
  const [variants, setVariants] = useState([]);  // Product Variants state
  const [productSearchTerm, setProductSearchTerm] = useState("");
  const [currIndex, setCurrIndex] = useState();
  const [showPrintPreview, setShowPrintPreview] = useState(false)
  const [quotationData, setQuotationData] = useState(null);
  const [selectrfProductName, setSelectedProductName] = useState();
  const [selectedBrand, setSelectedBrand] = useState();
  const [productSuggestionList, setProductSuggestionList] = useState([]);
  // const [currIndex,setCurrIndex] = useEffect();
  const [highlightedIndex, setHighlightedIndex] = useState(-1); // Track the highlighted suggestion index
  const suggestionsRef = useRef(null);

  const handleKeyDown = (e) => {
    if (e.key === 'ArrowDown') {
      setHighlightedIndex((prev) => Math.min(prev + 1, productSuggestionList.length - 1));
      e.preventDefault(); // Prevent default scrolling behavior
    } else if (e.key === 'ArrowUp') {
      setHighlightedIndex((prev) => Math.max(prev - 1, 0));
      e.preventDefault(); // Prevent default scrolling behavior
    } else if (e.key === 'Enter') {
      if (highlightedIndex >= 0) {
        handleSelectProduct(currIndex, productSuggestionList[highlightedIndex]);
        setHighlightedIndex(-1); // Reset highlighted index
      }
    } else if (e.key === 'Escape') {
      setHighlightedIndex(-1); // Reset highlighted index on escape
    }
  };
  const handleKeyDownClientSearch = (e) => {
    if (e.key === 'ArrowDown') {
      setHighlightedIndex((prev) => Math.min(prev + 1, clientSuggestions.length - 1));
      e.preventDefault();
    } else if (e.key === 'ArrowUp') {
      setHighlightedIndex((prev) => Math.max(prev - 1, 0));
      e.preventDefault();
    } else if (e.key === 'Enter') {
      if (highlightedIndex >= 0) {
        const client = clientSuggestions[highlightedIndex];
        setSelectedClient(client);
        setClientSearchTerm(''); // Clear search term after selection
        setHighlightedIndex(-1); // Reset highlighted index
      }
    } else if (e.key === 'Escape') {
      setHighlightedIndex(-1); // Reset highlighted index on escape
    }
  };
  useEffect(() => {
    if (suggestionsRef.current) {
      const activeItem = suggestionsRef.current.children[highlightedIndex];
      if (activeItem) {
        activeItem.scrollIntoView({
          block: 'nearest',
        });
      }
    }
  }, [highlightedIndex]);

  useEffect(() => {
    if (selectedCompany)
      setTerms(selectedCompany.terms.termsAndConditions);
  }, [])

  useEffect(() => {
    fetchProductSuggestions(currIndex, productSearchTerm)
  }, [productSearchTerm, currIndex])

  // Fetch client suggestions as the user types
  useEffect(() => {
    if (!clientSearchTerm) return;

    const fetchClients = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await axios.get(`https://emkayindia.in/api/v1/client/search?companyID=${selectedCompany.id}&&name=${clientSearchTerm}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setClientSuggestions(response.data);
      } catch (error) {
        console.error('Error fetching clients:', error);
        setClientSuggestions([]);
      }
    };
    fetchClients();
  }, [clientSearchTerm, selectedCompany]);

  // Handle when client is selected from the dropdown
  const handleClientChange = (client) => {
    setSelectedClient(client);
    setClientSearchTerm(`${client.name} - ${client.nickname} - ${client.gstNo}`);
    setClientSuggestions([]);
  };


  // Handle API call to get product suggestions
  // const fetchProductSuggestions = async (index, searchTerm) => {
  //   if (!searchTerm) return;

  //   const token = localStorage.getItem('token');
  //   // if (searchTerm.length > 3) {
  //     try {
  //       const response = await axios.get(`https://emkayindia.in/api/v1/product/name?companyId=${selectedCompany.id}&&name=${searchTerm}`, {
  //         headers: { Authorization: `Bearer ${token}` }
  //       });
  //       updateBillingProduct(index, { suggestions: response.data });
  //     } catch (error) {
  //       console.error('Error fetching product suggestions:', error);
  //     }
  //   // }
  // };
  const fetchProductSuggestions = async (index, searchTerm) => {
    if (!searchTerm) return;

    const token = localStorage.getItem('token');

    try {
      const response = await axios.get(`https://emkayindia.in/api/v1/product/name?companyId=${selectedCompany.id}&&name=${searchTerm}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setProductSuggestionList(response.data);
      updateBillingProduct(index, { suggestions: response.data }); // Update suggestions for the row
    } catch (error) {
      console.error('Error fetching product suggestions:', error);
    }
  };

  // Debounced effect for each row (to delay the API call)
  // const debounce = (callback, delay) => {
  //   let timer;
  //   return (...args) => {
  //     clearTimeout(timer);
  //     timer = setTimeout(() => callback(...args), delay);
  //   };
  // };

  const handleProductSearchChange = (index, searchTerm) => {
    setCurrIndex(index);
    // setProductSearchTerm(searchTerm);
    // fetchProductSuggestions(index, searchTerm);
  }; // Debounce API call by 500ms


  // Update billing product in specific row
  const updateBillingProduct = (index, updatedProductData, productSearchTerm) => {
    const updatedProducts = billingProducts.map((product, i) =>
      i === index ? { ...product, ...updatedProductData } : product
    );
    console.log(updatedProductData);
    setBillingProducts(updatedProducts);
  };

  // Handle product selection
  const handleSelectProduct = async (index, product) => {
    const token = localStorage.getItem('token');
    setSelectedProductName(product);
    try {
      const brandsResponse = await axios.get(`https://emkayindia.in/api/v1/product/brand?companyId=${selectedCompany.id}&&name=${product}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setBrands(brandsResponse.data);
      updateBillingProduct(index, { serialNo: index + 1, name: product, suggestions: [] });
    } catch (error) {
      console.error('Error fetching brands:', error);
    }
  };

  // Handle brand selection and fetch variants
  const selectBrandHandler = async (index, brandName) => {
    const token = localStorage.getItem('token');
    try {
      const variantsResponse = await axios.get(`https://emkayindia.in/api/v1/product/variant?companyId=${selectedCompany.id}&&name=${selectrfProductName}&&brand=${brandName}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setVariants(variantsResponse.data);
      setSelectedBrand(brandName);
      updateBillingProduct(index, { brand: brandName });
    } catch (error) {
      console.error('Error fetching variants:', error);
    }
  };

  // Handle variant selection and fetch product details
  const handleVariantSelect = async (index, variant) => {
    const token = localStorage.getItem('token');
    console.log(variant);
    try {
      const productResponse = await axios.get(`https://emkayindia.in/api/v1/product/search?companyId=${selectedCompany.id}&&name=${selectrfProductName}&&brand=${selectedBrand}&&variant=${variant}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      const productDetails = productResponse.data[0];
      // console.log(productDetails.id);
      updateBillingProduct(index, { variant, gst: productDetails.gst, price: productDetails.price, hsn: productDetails.hsn, productId: productDetails.id, quantity: 1 });
      setBrands([]);
      setVariants([]);
    } catch (error) {
      console.error('Error fetching product details:', error);
    }
  };

  // Calculate the total for each product row
  const calculateRowTotal = (product) => {
    const subtotal = product.quantity * product.price;
    const discountAmount = (subtotal * product.discount) / 100;
    const gstAmount = (subtotal - discountAmount) * (product.gst / 100);
    return subtotal - discountAmount;
  };

  // Calculate the overall total for the invoice
  const calculateCompleteTotal = () => {
    return billingProducts.reduce((total, product) => total + calculateRowTotal(product), 0).toFixed(2);
  };

  // Handle adding a new row
  const handleAddRow = () => {
    setBillingProducts([...billingProducts, initialProductRow]);
    console.log(billingProducts);
  };

  // Handle removing a row
  const handleRemoveRow = (Index) => {
    // console.log(billingProducts)
    // setBillingProducts(billingProducts.filter((_, i) => i !== index));
    setBillingProducts((prevBillingProducts) => {
      // Filter out the row that needs to be removed
      const updatedProducts = prevBillingProducts.filter((_, index) => index !== Index);

      // Ensure the subsequent rows are reset after the removal to prevent data persisting
      const clearedProducts = updatedProducts.map((product, index) => {
        if (index >= Index) {
          // Clear fields in rows that were shifted up
          return {
            ...product,
            name: '',      // Clear the name
            variant: '',   // Clear the variant
            brand: '',     // Clear the brand
            searchTerm: '', // Reset the search term
            suggestions: [], // Clear suggestions
          };
        }
        return product;
      });

      return clearedProducts;
    });

  };

  // Handle form submission and create the quotation
  const handleSubmit = async () => {
    const validProducts = billingProducts.filter(product => product.name && product.quantity);
    if (!selectedCompany || !selectedClient || validProducts.length === 0) {
      alert('Please fill out all fields before submitting.');
      return;
    }

    const token = localStorage.getItem('token');
    const newQuotationData = {
      clientId: selectedClient.id,
      companyId: selectedCompany.id,
      items: validProducts.map(product => ({
        productId: product.productId,
        serialNo: product.serialNo,
        name: product.name,
        hsn: product.hsn,
        brand: product.brand,
        variant: product.variant,
        gst: String(product.gst),
        total: String(calculateRowTotal(product)),
        quantity: parseInt(product.quantity),
        discount: parseFloat(product.discount) || 0,
        price: product.price,
      })),
      terms: terms,
      banner: selectedCompany.banner
    };

    console.log(newQuotationData.items);

    try {
      const response = await axios.post('https://emkayindia.in/api/v1/quotation/create', newQuotationData, {
        headers: { Authorization: `Bearer ${token}` }
      });
      if (response.status === 200) {
        toast.success("Quotation created successfully!");
        setShowPrintPreview(true);
        setPrintData(response.data.quotation);
        console.log("res ", response.data.quotation);
        console.log("res print", printData);

      }
    } catch (error) {
      toast.error("Failed to create quotation.");
      console.error('Error submitting quotation:', error);
    }
  };
  const handleTermsChange = (index, value) => {
    // Create a new array to avoid direct state mutation
    const newTerms = [...terms];
    newTerms[index] = value;  // Update the specific term at the given index
    setTerms(newTerms);  // Update the state with the new array
    console.log(terms);
  };
  const handlePrint = () => {

    //    const printContents = document.getElementById('print-preview').innerHTML;
    // const originalContents = document.body.innerHTML;

    // document.body.innerHTML = printContents; // Temporarily replace body with the print content

    window.print();
    // document.body.innerHTML = originalContents; 
  }
  useEffect(() => {
    if (printData) {
      // Proceed only after printData is updated
      setShowPrintPreview(true);
      console.log("Print preview data:", printData);
      // Add further logic that depends on printData being set here
    }
  }, [printData]);
  if (showPrintPreview) {
    return (
      <div className="print-preview w-[100%] h-[100%]">
        <A4Quotation quotationData={printData} className='print' />
        <div className="print-actions">
          <button onClick={handlePrint} className="print-button">Print Quotation</button>
          <div>
            <button onClick={handlePrint} className="print-button">Print Quotation</button>
            <button onClick={() => setShowPrintPreview(false)} className="back-button">Back to Edit</button>
          </div>

        </div>
        <style jsx>{`
          .print-preview {
            background: white;
            // padding: 20px;
            border-radius: 8px;
            width:100%;
            height:100%;
            box-shadow: 0 0 10px rgba(0,0,0,0.1);
          }
          .print-actions {
            // margin-top: 20px;
            display: flex;
            justify-content: space-between;
          }
          .print-button, .back-button {
            padding: 10px 20px;
            border: none;
            border-radius: 4px;
            cursor: pointer;
            font-size: 16px;
          }
          .print-button {
            background-color: #4CAF50;
            color: white;
          }
          .back-button {
            background-color: #f44336;
            color: white;
          }
          @media print {
            .print-actions {
              display: none;
            }
          }
        `}</style>
      </div>
    )
  };

  return (
    <div>
      {
        selectedCompany &&


        <div className="CreateQuotation-component">

          <div className='flex justify-between w-[90%] mt-2'>
            <h2 className='create-bill-heading'>Create Quotation</h2>
            <div onClick={() => toggleComponent('AddClient')}
              style={{ backgroundColor: '#343a40' }} // Custom background color
              className="text-white h-8 py-1 px-2 rounded  transition duration-300 ease-in-out hover:white h-1] "
            >
              Add Client
            </div>
          </div>

          {/* Bill Type */}
          {/* <div className="bill-type-container">
            <div className='label'>Select Quotation Type:</div>
            <select value={billType} onChange={(e) => setBillType(e.target.value)}>
              <option value="PI">Proforma Invoice</option>
              <option value="Quotation">Quotation</option>
            </select>
          </div> */}

          {/* Client Selection */}
          <div className="client-section">
            <div className='flex justify-between w-[110%] '>
              <div className='w-[80%]'>
                <label>Select Client:</label>
                <input
                  type="text"
                  value={clientSearchTerm}
                  onChange={(e) => {
                    setClientSearchTerm(e.target.value);
                    // Optionally, filter clientSuggestions based on search term
                  }}
                  placeholder="Type client name, nickname, or GST number"
                  className="border border-gray-300 rounded-md p-2 mb-4 w-full"
                  onKeyDown={handleKeyDownClientSearch} // Add keyboard event handling
                />
              </div>


            </div>


            {clientSearchTerm && clientSuggestions.length > 0 && (
              <ul className="suggestions-list border  border-gray-300 rounded-md h-auto overflow-y-auto"
                ref={suggestionsRef}
              >
                {clientSuggestions.map((client, index) => (
                  <li
                    key={client.id}
                    onClick={() => {
                      setSelectedClient(client);
                      setClientSearchTerm(''); // Clear search term after selection
                    }}
                    className={`p-2 cursor-pointer ${highlightedIndex === index ? 'bg-gray-200' : ''}`}
                    onMouseEnter={() => setHighlightedIndex(index)} // Highlight on mouse hover
                  >
                    {client.name} - {client.nickname} - {client.gstNo}
                  </li>
                ))}
              </ul>
            )}

            {selectedClient && (
              <div className="client-details">
                <p><strong>Name:</strong> {selectedClient.name}</p>
                <p><strong>Nickname:</strong> {selectedClient.nickname}</p>
                <p><strong>Email:</strong> {selectedClient.email}</p>
                <p><strong>GST No.:</strong> {selectedClient.gstNo}</p>
                <p><strong>Phone:</strong> {selectedClient.phone}</p>
                <p><strong>Address:</strong> {selectedClient.address}</p>
                <p><strong>Contact Person:</strong> {selectedClient.contactPerson}</p>
              </div>
            )}
          </div>

          {/* Product Table */}
          <table className="product-table">
            <thead>
              <tr>
                <th>Serial No.</th>
                <th>Product</th>

                <th>Brand</th>
                <th>Variant</th>
                <th>HSN</th>
                <th>Quantity</th>
                <th>Price</th>
                <th>Discount</th>
                <th>GST</th>
                <th>Total</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {billingProducts.map((product, index) => (
                <tr key={index}>
                  {/* <td><input type="text" value={index+1} onChange={(e) => updateBillingProduct(index, { serialNo: e.target.value })} /></td> */}
                  <td><p>{index + 1}</p></td>
                  <td>
                    <input
                      type="text"
                      value={(product.name) ? product.name : product.searchTerm}
                      placeholder='Search Product'
                      onChange={(e) => {
                        if (index > 0 && (!billingProducts[index - 1].name || !billingProducts[index - 1].variant || !billingProducts[index - 1].brand)) {
                          // Display warning and prevent input
                          alert("Please fill out the previous row before proceeding.");
                          return;
                        }
                        setProductSearchTerm(e.target.value);
                        e.preventDefault();
                        updateBillingProduct(index, { name: e.target.value }); // Update the product name in the state
                        // fetchProductSuggestions(index, e.target.value); // Fetch suggestions
                        handleProductSearchChange(index, product.searchTerm);
                      }}
                      onKeyDown={handleKeyDown}

                    />
                    {product.suggestions.length > 0 && (
                      <ul className="suggestions-list"
                        ref={suggestionsRef} // Reference for scrolling to highlighted item
                      >
                        {product.suggestions.map((suggestion, i) => (
                          <li key={suggestion.id} onClick={() => handleSelectProduct(index, suggestion)}
                            className={`p-2 cursor-pointer ${highlightedIndex === i ? 'bg-gray-200' : ''}`} // Highlighting the selected suggestion
                            onMouseEnter={() => setHighlightedIndex(i)}
                          >
                            {suggestion}
                          </li>
                        ))}
                      </ul>
                    )}

                  </td>

                  <td>
                    {product.name ? (
                      product.brand ? (
                        // Display the brand as non-editable text once selected
                        <input type="text" value={product.brand} readOnly />
                      ) : (
                        // Display the brand dropdown if a product name is selected
                        <select value={product.brand} onChange={(e) =>

                          selectBrandHandler(index, e.target.value)}>
                          <option value="">Select Brand</option>
                          {brands.map((brand, i) => <option key={i} value={brand}>{brand}</option>)}
                        </select>
                      )
                    ) : (
                      // If product name is not selected, show this message
                      <input type="text" placeholder="Select Brand" readOnly />
                    )}
                  </td>
                  <td>
                    {
                      product.name && product.brand ? (
                        product.variant ? (
                          // Display the variant as non-editable text once selected
                          <input type="text" value={product.variant} readOnly />
                        ) : (
                          // Display the variant dropdown if both product and brand are selected
                          <select value={product.variant} onChange={(e) => handleVariantSelect(index, e.target.value)}>
                            <option value="">Select Variant</option>
                            {variants.map((variant, i) => <option key={i} value={variant}>{variant}</option>)}
                          </select>
                        )
                      ) : (
                        // If product or brand is not selected, show this message
                        <input type="text" placeholder="Select Variant" readOnly />
                      )
                    }
                  </td>
                  <td><input type="text" value={product.hsn} onChange={(e) => updateBillingProduct(index, { hsn: e.target.value })} /></td>
                  <td><input type="number" value={product.quantity} onChange={(e) => updateBillingProduct(index, { quantity: e.target.value })} /></td>
                  <td><input type="number" value={product.price} onChange={(e) => updateBillingProduct(index, { price: e.target.value })} /></td>
                  <td><input type="number" value={product.discount } onChange={(e) => updateBillingProduct(index, { discount: e.target.value })} /></td>
                  <td><input type="number" value={product.gst} onChange={(e) => updateBillingProduct(index, { gst: e.target.value })} /></td>
                  <td>{calculateRowTotal(product).toFixed(2)}</td>
                  <td><button onClick={() => handleRemoveRow(index)}>X</button></td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className='flex  justify-between mr-[10rem] align-center'>
            <button onClick={handleAddRow} className='add-row'>Add Row</button>
            {/* Overall Total */}
            <div className="total-section">
              <h3 className="font-bold">Total: ₹{calculateCompleteTotal()}</h3>
            </div>
          </div>



          {/* Terms & Conditions */}
          <div className="terms-section">
            <label>Terms and Conditions</label>
            {selectedCompany && terms.map((term, index) => (
              <input
                type='text'
                key={index}
                value={term}
                onChange={(e) => handleTermsChange(index, e.target.value)}
                placeholder="Enter terms and conditions"
              />
            ))}
          </div>



          {/* Submit Button */}
          <div className="w-full flex justify-center items-center">
            <button
              onClick={handleSubmit}
              style={{ backgroundColor: '#343a40' }} // Custom background color
              className="text-white font-semibold py-2 px-4 rounded-lg shadow-lg transition duration-300 ease-in-out hover:white mt-7 mb-8" // Change opacity on hover
            >
              Create Quotation
            </button>
          </div>


        </div>
      }
      {
        !selectedCompany && <div className='flex justify-center align-middle h-[100%]'>
          <p className='my-24 text-red-500 font-semibold mx-36'>Select the company first</p>
        </div>
      }
    </div>
  );

};

export default CreateQuotation;
