// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// // import EditUser from './EditUser';

// const AllUsers = () => {
//   const [users, setUsers] = useState([]);
//   const [searchQuery, setSearchQuery] = useState('');
//   const [selectedRole, setSelectedRole] = useState(''); // New state for role filter
//   const [selectedUser, setSelectedUser] = useState(null);

//   useEffect(() => {
//     const token = localStorage.getItem('token');
//     axios.get('https://emkayindia.in/api/v1/user/all', {
//       headers: { 'Authorization': `Bearer ${token}` }
//     })
//       .then(response => setUsers(response.data))
//       .catch(error => console.error('Error fetching users:', error));
//   }, []);

//   const handleSearch = (event) => {
//     setSearchQuery(event.target.value);
//   };

//   const handleRoleChange = (event) => {
//     setSelectedRole(event.target.value);
//   };

//   const handleEditUser = (user) => {
//     setSelectedUser(user);
//   };

//   const handleUserUpdate = (updatedUser) => {
//     setUsers(prevUsers => 
//       prevUsers.map(user => user.id === updatedUser.id ? updatedUser : user)
//     );
//     setSelectedUser(null);
//   };

//   // Filter users based on both search query and selected role
//   const filteredUsers = users.filter(user =>
//     user.name.toLowerCase().includes(searchQuery.toLowerCase()) &&
//     (selectedRole === '' || user.role === selectedRole)
//   );

//   return (
//     <div className="p-4 w-[83%] ml-60 mt-10">
//       <h2 className="text-2xl font-semibold mb-4">All Users</h2>

//       {/* Search Input */}
//       <div className="mb-4 flex space-x-4">
//         <div>
//           <label className="block text-gray-700 mb-1">Search User Name:</label>
//           <input
//             type="text"
//             value={searchQuery}
//             onChange={handleSearch}
//             placeholder="Search users..."
//             className="w-full p-2 border border-gray-300 rounded-md"
//           />
//         </div>

//         {/* Role Filter Dropdown */}
//         <div>
//           <label className="block text-gray-700 mb-1">Filter by Role:</label>
//           <select
//             value={selectedRole}
//             onChange={handleRoleChange}
//             className="w-full p-2 border border-gray-300 rounded-md"
//           >
//             <option value="">All Roles</option>
//             <option value="USER">USER</option>
//             <option value="ADMIN">ADMIN</option>
//             <option value="SUPERADMIN">SUPERADMIN</option>
//           </select>
//         </div>
//       </div>

//       {/* Users Table */}
//       <table className="w-full border-collapse border border-gray-300">
//         <thead>
//           <tr className="bg-gray-100">
//             <th className="border border-gray-300 p-2">ID</th>
//             <th className="border border-gray-300 p-2">Name</th>
//             <th className="border border-gray-300 p-2">Email</th>
//             <th className="border border-gray-300 p-2">Role</th>
//             <th className="border border-gray-300 p-2">Actions</th>
//           </tr>
//         </thead>
//         <tbody>
//           {filteredUsers.map(user => (
//             <tr key={user.id} className="hover:bg-gray-50">
//               <td className="border border-gray-300 p-2">{user.id}</td>
//               <td className="border border-gray-300 p-2">{user.name}</td>
//               <td className="border border-gray-300 p-2">{user.email}</td>
//               <td className="border border-gray-300 p-2">{user.role}</td>
//               <td className="border border-gray-300 p-2 text-center">
//                 <button
//                   onClick={() => handleEditUser(user)}
//                   className="bg-blue-500 text-white px-4 py-1 rounded hover:bg-blue-600"
//                 >
//                   Edit
//                 </button>
//               </td>
//             </tr>
//           ))}
//         </tbody>
//       </table>

//       {/* {selectedUser && (
//         <EditUser
//           user={selectedUser}
//           onSave={handleUserUpdate}
//           onCancel={() => setSelectedUser(null)}
//         />
//       )} */}
//     </div>
//   );
// };

// export default AllUsers;



// import React, { useState, useEffect } from 'react';
// import axios from 'axios';

// const AllUsers = () => {
//   const [users, setUsers] = useState([]);
//   const [searchQuery, setSearchQuery] = useState('');
//   const [selectedRole, setSelectedRole] = useState('');
//   const [selectedUser, setSelectedUser] = useState(null);

//   useEffect(() => {
//     const token = localStorage.getItem('token');
//     axios.get('https://emkayindia.in/api/v1/user/all', {
//       headers: { 'Authorization': `Bearer ${token}` }
//     })
//       .then(response => setUsers(response.data))
//       .catch(error => console.error('Error fetching users:', error));
//   }, []);

//   const handleSearch = (event) => {
//     setSearchQuery(event.target.value);
//   };

//   const handleRoleChange = (event) => {
//     setSelectedRole(event.target.value);
//   };

//   const handleEditUser = (user) => {
//     setSelectedUser(user);
//   };

//   const handleUserUpdate = (updatedUser) => {
//     setUsers(prevUsers => 
//       prevUsers.map(user => user.id === updatedUser.id ? updatedUser : user)
//     );
//     setSelectedUser(null);
//   };

//   // Filter users based on both search query and selected role
//   const filteredUsers = users.filter(user =>
//     user.name.toLowerCase().includes(searchQuery.toLowerCase()) &&
//     (selectedRole === '' || user.role === selectedRole)
//   );

//   return (
//     <div className="p-4 w-[83%] ml-60 mt-10">
//       <h2 className="text-2xl font-semibold mb-4">All Users</h2>

//       {/* Search Input */}
//       <div className="mb-4 flex space-x-4">
//         <div>
//           <label className="block text-gray-700 mb-1">Search User Name:</label>
//           <input
//             type="text"
//             value={searchQuery}
//             onChange={handleSearch}
//             placeholder="Search users..."
//             className="w-full p-2 border border-gray-300 rounded-md"
//           />
//         </div>

//         {/* Role Filter Dropdown */}
//         <div>
//           <label className="block text-gray-700 mb-1">Filter by Role:</label>
//           <select
//             value={selectedRole}
//             onChange={handleRoleChange}
//             className="w-full p-2 border border-gray-300 rounded-md"
//           >
//             <option value="">All Roles</option>
//             <option value="USER">USER</option>
//             <option value="ADMIN">ADMIN</option>
//             <option value="SUPERADMIN">SUPERADMIN</option>
//           </select>
//         </div>
//       </div>

//       {/* Users Table */}
//       <table className="w-full border-collapse border border-gray-300">
//         <thead>
//           <tr className="bg-gray-100">
//             <th className="border border-gray-300 p-2">ID</th>
//             <th className="border border-gray-300 p-2">Name</th>
//             <th className="border border-gray-300 p-2">Email</th>
//             <th className="border border-gray-300 p-2">Role</th>
//             <th className="border border-gray-300 p-2">Actions</th>
//           </tr>
//         </thead>
//         <tbody>
//           {filteredUsers.map(user => (
//             <tr key={user.id} className="hover:bg-gray-50">
//               <td className="border border-gray-300 p-2">{user.id}</td>
//               <td className="border border-gray-300 p-2">{user.name}</td>
//               <td className="border border-gray-300 p-2">{user.email}</td>
//               <td className="border border-gray-300 p-2">{user.role}</td>
//               <td className="border border-gray-300 p-2 text-center">
//                 <button
//                   onClick={() => handleEditUser(user)}
//                   className="bg-blue-500 text-white px-4 py-1 rounded hover:bg-blue-600"
//                 >
//                   Edit
//                 </button>
//               </td>
//             </tr>
//           ))}
//         </tbody>
//       </table>

//       {/* User Details or Edit */}
//       {selectedUser && (
//         <div className="mt-6 bg-white p-4 border border-gray-300 rounded-lg">
//           <h3 className="text-xl font-semibold mb-4">User Details</h3>
//           <div>
//             <p><strong>ID:</strong> {selectedUser.id}</p>
//             <p><strong>Name:</strong> {selectedUser.name}</p>
//             <p><strong>Email:</strong> {selectedUser.email}</p>
//             <p><strong>Role:</strong> {selectedUser.role}</p>
//             {/* Optionally include other user details */}
//           </div>

//           {/* Edit User Form (if desired) */}
//           <div className="mt-4">
//             <button
//               onClick={() => handleUserUpdate(selectedUser)}
//               className="bg-green-500 text-white px-4 py-1 rounded hover:bg-green-600"
//             >
//               Save Changes
//             </button>
//             <button
//               onClick={() => setSelectedUser(null)}
//               className="ml-2 bg-red-500 text-white px-4 py-1 rounded hover:bg-red-600"
//             >
//               Cancel
//             </button>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default AllUsers;



// import React, { useState, useEffect } from 'react';
// import axios from 'axios';

// const AllUsers = () => {
//   const [users, setUsers] = useState([]);
//   const [searchQuery, setSearchQuery] = useState('');
//   const [selectedRole, setSelectedRole] = useState('');
//   const [selectedUser, setSelectedUser] = useState(null);
//   const [editUser,setEditUser] = useState(null);

//   useEffect(() => {
//     const token = localStorage.getItem('token');
//     axios.get('https://emkayindia.in/api/v1/user/all', {
//       headers: { 'Authorization': `Bearer ${token}` }
//     })
//       .then(response => setUsers(response.data))
//       .catch(error => console.error('Error fetching users:', error));
//   }, []);

//   const handleSearch = (event) => {
//     setSearchQuery(event.target.value);
//   };

//   const handleRoleChange = (event) => {
//     setSelectedRole(event.target.value);
//   };

//   const handleUserClick = (user) => {
//     setSelectedUser(user); // Display user details
//   };

//   const handleEditUser = (user) => {
//     setEditUser(user);
//     setSelectedUser(user); // Prepare for editing
//   };

//   const handleUserUpdate = (updatedUser) => {
//     setUsers(prevUsers => 
//       prevUsers.map(user => user.id === updatedUser.id ? updatedUser : user)
//     );
//     setSelectedUser(null);
//   };

//   // Filter users based on search query and selected role
//   const filteredUsers = users.filter(user =>
//     user.name.toLowerCase().includes(searchQuery.toLowerCase()) &&
//     (selectedRole === '' || user.role === selectedRole)
//   );

//   return (
//     <div className="p-4 w-[83%] ml-60 mt-10">
//       <h2 className="text-2xl font-semibold mb-4">All Users</h2>

//       {/* Conditionally render either user list or selected user details */}
//       {selectedUser ? (
//         <div className="mt-6 p-4 bg-gray-100 rounded shadow">
//           <h3 className="text-xl font-semibold mb-2">User Details</h3>
//           <p><strong>ID:</strong> {selectedUser.id}</p>
//           <p><strong>Name:</strong> {selectedUser.name}</p>
//           <p><strong>Email:</strong> {selectedUser.email}</p>
//           <p><strong>Role:</strong> {selectedUser.role}</p>
//           <p><strong>Phone:</strong> {selectedUser.phone}</p>
//           <p><strong>Address:</strong> {selectedUser.address}</p>
//           {/* Add more details if needed */}
//           <button
//             onClick={() => setSelectedUser(null)} // Close details view
//             className="mt-4 bg-red-500 text-white px-4 py-1 rounded hover:bg-red-600"
//           >
//             Close
//           </button>
//         </div>
//       ) : (
//         <>
//           {/* Search and Role Filter */}
//           <div className="mb-4 flex space-x-4">
//             <div>
//               <label className="block text-gray-700 mb-1">Search User Name:</label>
//               <input
//                 type="text"
//                 value={searchQuery}
//                 onChange={handleSearch}
//                 placeholder="Search users..."
//                 className="w-full p-2 border border-gray-300 rounded-md"
//               />
//             </div>

//             <div>
//               <label className="block text-gray-700 mb-1">Filter by Role:</label>
//               <select
//                 value={selectedRole}
//                 onChange={handleRoleChange}
//                 className="w-full p-2 border border-gray-300 rounded-md"
//               >
//                 <option value="">All Roles</option>
//                 <option value="USER">USER</option>
//                 <option value="ADMIN">ADMIN</option>
//                 <option value="SUPERADMIN">SUPERADMIN</option>
//               </select>
//             </div>
//           </div>

//           {/* Users Table */}
//           <table className="w-full border-collapse border border-gray-300">
//             <thead>
//               <tr className="bg-gray-100">
//                 <th className="border border-gray-300 p-2">ID</th>
//                 <th className="border border-gray-300 p-2">Name</th>
//                 <th className="border border-gray-300 p-2">Email</th>
//                 <th className="border border-gray-300 p-2">Role</th>
//                 <th className="border border-gray-300 p-2">Actions</th>
//               </tr>
//             </thead>
//             <tbody>
//               {filteredUsers.map(user => (
//                 <tr
//                   key={user.id}
//                   className="hover:bg-gray-50 cursor-pointer"
//                   onClick={() => handleUserClick(user)}
//                 >
//                   <td className="border border-gray-300 p-2">{user.id}</td>
//                   <td className="border border-gray-300 p-2">{user.name}</td>
//                   <td className="border border-gray-300 p-2">{user.email}</td>
//                   <td className="border border-gray-300 p-2">{user.role}</td>
//                   <td className="border border-gray-300 p-2 text-center">
//                     <button
//                       onClick={(e) => {
//                         e.stopPropagation(); // Prevent row click from firing
//                         handleEditUser(user);
//                       }}
//                       className="bg-blue-500 text-white px-4 py-1 rounded hover:bg-blue-600"
//                     >
//                       Edit
//                     </button>
//                   </td>
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//         </>
//       )}
//     </div>
//   );
// };

// export default AllUsers;


import React, { useState, useEffect } from 'react';
import axios from 'axios';
import EditUser from '../components/editComponent/EditUser';

const AllUsers = () => {
  const [users, setUsers] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedRole, setSelectedRole] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);
  const [editUser, setEditUser] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem('token');
    axios.get('https://emkayindia.in/api/v1/user/all', {
      headers: { 'Authorization': `Bearer ${token}` }
    })
      .then(response => setUsers(response.data))
      .catch(error => console.error('Error fetching users:', error));
  }, []);

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleRoleChange = (event) => {
    setSelectedRole(event.target.value);
  };

  const handleUserClick = (user) => {
    setSelectedUser(user);
    setEditUser(null); // Reset edit mode if viewing details
  };

  const handleEditUser = (user) => {
    setEditUser(user); // Open edit mode with selected user
    setSelectedUser(null); // Close details view if editing
  };

  const handleUserUpdate = (updatedUser) => {
    setUsers(prevUsers =>
      prevUsers.map(user => user.id === updatedUser.id ? updatedUser : user)
    );
    setEditUser(null);
  };

  const filteredUsers = users.filter(user =>
    user.name.toLowerCase().includes(searchQuery.toLowerCase()) &&
    (selectedRole === '' || user.role === selectedRole)
  );

  return (
    <div className="p-4 w-[83%] ml-60 mt-10">
      {!editUser && !selectedUser && <h2 className="text-2xl font-semibold mb-4">All Users</h2>}

      {editUser ? (
        <EditUser
          userId={editUser.id}
          onSave={handleUserUpdate}
          onCancel={() => setEditUser(null)}
        />
      ) : selectedUser ? (
        <div className="mt-6 p-6 bg-white rounded-lg shadow-md border border-gray-200">
          <h3 className="text-2xl font-semibold mb-4 text-blue-600">User Details</h3>

          <div className="space-y-2 text-gray-700">
            <p className="flex items-center">
              <strong className="w-24">ID:</strong> <span>{selectedUser.id}</span>
            </p>
            <p className="flex items-center">
              <strong className="w-24">Name:</strong> <span>{selectedUser.name}</span>
            </p>
            <p className="flex items-center">
              <strong className="w-24">Email:</strong> <span>{selectedUser.email}</span>
            </p>
            <p className="flex items-center">
              <strong className="w-24">Role:</strong> <span>{selectedUser.role}</span>
            </p>
            <p className="flex items-center">
              <strong className="w-24">Phone:</strong> <span>{selectedUser.phone}</span>
            </p>
            <div className="flex flex-col items-start">
              {selectedUser.address &&
                selectedUser.address.split("0/").map((line, index) => (
                  <div className=''>
                  <strong className="w-24">Address line {index+1}:</strong>
                  <span key={index} className="ml-4" >{line}</span>
                  </div>
                ))}
            </div>
          </div>

          <button
            onClick={() => setSelectedUser(null)}
            className="mt-6 bg-red-500 text-white font-medium px-6 py-2 rounded-lg hover:bg-red-600 transition-colors duration-200 ease-in-out w-[10%]"
          >
            Back
          </button>
        </div>


      ) : (
        <>
          <div className="mb-4 flex space-x-4">
            <div>
              <label className="block text-gray-700 mb-1">Search User Name:</label>
              <input
                type="text"
                value={searchQuery}
                onChange={handleSearch}
                placeholder="Search users..."
                className="w-full p-2 border border-gray-300 rounded-md"
              />
            </div>
            <div>
              <label className="block text-gray-700 mb-1">Filter by Role:</label>
              <select
                value={selectedRole}
                onChange={handleRoleChange}
                className="w-full p-2 border border-gray-300 rounded-md"
              >
                <option value="">All Roles</option>
                <option value="USER">USER</option>
                <option value="ADMIN">ADMIN</option>
                <option value="SUPERADMIN">SUPERADMIN</option>
              </select>
            </div>
          </div>

          <table className="w-full border-collapse border border-gray-300">
            <thead>
              <tr className="bg-gray-100">
                <th className="border border-gray-300 p-2">ID</th>
                <th className="border border-gray-300 p-2">Name</th>
                <th className="border border-gray-300 p-2">Email</th>
                <th className="border border-gray-300 p-2">Role</th>
                <th className="border border-gray-300 p-2">Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredUsers.map(user => (
                <tr
                  key={user.id}
                  className="hover:bg-gray-50 cursor-pointer"
                  onClick={() => handleUserClick(user)}
                >
                  <td className="border border-gray-300 p-2">{user.id}</td>
                  <td className="border border-gray-300 p-2">{user.name}</td>
                  <td className="border border-gray-300 p-2">{user.email}</td>
                  <td className="border border-gray-300 p-2">{user.role}</td>
                  <td className="border border-gray-300 p-2 text-center">
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        handleEditUser(user);
                      }}
                      className="bg-blue-500 text-white px-4 py-1 rounded hover:bg-blue-600"
                    >
                      Edit
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
    </div>
  );
};

export default AllUsers;
