// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// // import './css/EditCompany.css';

// const EditCompany = ({ companyId, onSave, onCancel }) => {
//   const [companyDetails, setCompanyDetails] = useState({
//     companyName: '',
//     gstNo: '',
//     email: '',
//     phone: '',
//     address: '',
//     logo: '',
//     banner: '',
//     termsAndConditions: [],
//     accountName: '',
//     bankName: '',
//     bankBranch: '',
//     bankAccount: '',
//     bankIFSC: '',
//   });

//   useEffect(() => {
//     // Fetch the company details by ID
//     const fetchCompanyDetails = async () => {
//       const token = localStorage.getItem('token');
//       try {
//         const response = await axios.get(`https://emkayindia.in/api/v1/company/get/${companyId}`, {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         });
//         setCompanyDetails({
//           ...response.data,
//           termsAndConditions: response.data.terms?.termsAndConditions || [],
//         });
//       } catch (error) {
//         console.error('Error fetching company details', error);
//       }
//     };

//     if (companyId) {
//       fetchCompanyDetails();
//     }
//   }, [companyId]);

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setCompanyDetails({ ...companyDetails, [name]: value });
//   };

//   const handleSave = async () => {
//     const token = localStorage.getItem('token');
//     try {
//       await axios.put(
//         `https://emkayindia.in/api/v1/company/${companyId}`,
//         {
//           ...companyDetails,
//           terms: { termsAndConditions: companyDetails.termsAndConditions },
//         },
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       );
//       onSave(); // Callback to refresh or close modal after saving
//     } catch (error) {
//       console.error('Error updating company details', error);
//     }
//   };

//   return (
//     <div className="edit-company-container p-6 w-[83%] ml-64 mt-14 bg-white rounded-lg shadow-md">
//       <h2 className="text-2xl font-bold mb-4">Edit Company</h2>
//       <div className="mb-4">
//         <label className="block font-medium text-gray-700">Company Name:</label>
//         <input
//           type="text"
//           name="companyName"
//           value={companyDetails.companyName}
//           onChange={handleChange}
//           className="w-full px-4 py-2 border rounded-lg"
//         />
//       </div>
//       <div className="mb-4">
//         <label className="block font-medium text-gray-700">GST No.:</label>
//         <input
//           type="text"
//           name="gstNo"
//           value={companyDetails.gstNo}
//           onChange={handleChange}
//           className="w-full px-4 py-2 border rounded-lg"
//         />
//       </div>
//       <div className="mb-4">
//         <label className="block font-medium text-gray-700">Email:</label>
//         <input
//           type="email"
//           name="email"
//           value={companyDetails.email}
//           onChange={handleChange}
//           className="w-full px-4 py-2 border rounded-lg"
//         />
//       </div>
//       <div className="mb-4">
//         <label className="block font-medium text-gray-700">Phone:</label>
//         <input
//           type="text"
//           name="phone"
//           value={companyDetails.phone}
//           onChange={handleChange}
//           className="w-full px-4 py-2 border rounded-lg"
//         />
//       </div>
//       <div className="mb-4">
//         <label className="block font-medium text-gray-700">Address:</label>
//         <input
//           type="text"
//           name="address"
//           value={companyDetails.address}
//           onChange={handleChange}
//           className="w-full px-4 py-2 border rounded-lg"
//         />
//       </div>
//       <div className="mb-4">
//         <label className="block font-medium text-gray-700">Logo URL:</label>
//         <input
//           type="text"
//           name="logo"
//           value={companyDetails.logo}
//           onChange={handleChange}
//           className="w-full px-4 py-2 border rounded-lg"
//         />
//       </div>
//       <div className="mb-4">
//         <label className="block font-medium text-gray-700">Banner URL:</label>
//         <input
//           type="text"
//           name="banner"
//           value={companyDetails.banner}
//           onChange={handleChange}
//           className="w-full px-4 py-2 border rounded-lg"
//         />
//       </div>
//       <div className="mb-4">
//         <label className="block font-medium text-gray-700">Terms & Conditions:</label>
//         <textarea
//           name="termsAndConditions"
//           value={companyDetails.termsAndConditions.join('\n')}
//           onChange={(e) => setCompanyDetails({ ...companyDetails, termsAndConditions: e.target.value.split('\n') })}
//           className="w-full px-4 py-2 border rounded-lg"
//           rows="4"
//         />
//       </div>
//       {/* Other fields */}
//       <div className="flex gap-4">
//         <button
//           className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
//           onClick={handleSave}
//         >
//           Save
//         </button>
//         <button
//           className="px-4 py-2 bg-gray-500 text-white rounded-lg hover:bg-gray-600"
//           onClick={onCancel}
//         >
//           Cancel
//         </button>
//       </div>
//     </div>
//   );
// };

// export default EditCompany;


// import React, { useState, useEffect } from 'react';
// import axios from 'axios';

// const EditCompany = ({ companyId, onSave, onCancel }) => {
//   const [company, setCompany] = useState({
//     companyName: '',
//     gstNo: '',
//     email: '',
//     phone: '',
//     address: '',
//     logo: null,
//     banner: null,
//     termsAndConditions: [],
//     accountName: '',
//     bankName: '',
//     bankBranch: '',
//     bankAccount: '',
//     bankIFSC: '',
//   });
//   const [initialLogo, setInitialLogo] = useState(null);
//   const [initialBanner, setInitialBanner] = useState(null);

//   useEffect(() => {
//     const fetchCompanyDetails = async () => {
//       const token = localStorage.getItem('token');
//       try {
//         const response = await axios.get(`https://emkayindia.in/api/v1/company/get/${companyId}`, {
//           headers: { Authorization: `Bearer ${token}` },
//         });
//         const companyData = response.data;
//         setCompany({
//           ...companyData,
//           termsAndConditions: companyData.terms.termsAndConditions || [],
//         });
//         setInitialLogo(companyData.logo);
//         setInitialBanner(companyData.banner);
//       } catch (error) {
//         console.error('Error fetching company details:', error);
//       }
//     };
//     fetchCompanyDetails();
//   }, [companyId]);

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setCompany({ ...company, [name]: value });
//   };

//   const handleImageChange = (e) => {
//     const { name, files } = e.target;
//     setCompany({ ...company, [name]: files[0] });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     const token = localStorage.getItem('token');
//     const formData = new FormData();

//     formData.append('companyName', company.companyName);
//     formData.append('gstNo', company.gstNo);
//     formData.append('email', company.email);
//     formData.append('phone', company.phone);
//     formData.append('address', company.address);
//     formData.append('termsAndConditions', JSON.stringify(company.termsAndConditions));
//     formData.append('accountName', company.accountName);
//     formData.append('bankName', company.bankName);
//     formData.append('bankBranch', company.bankBranch);
//     formData.append('bankAccount', company.bankAccount);
//     formData.append('bankIFSC', company.bankIFSC);

//     // Only append the logo and banner if they've been changed
//     if (company.logo !== initialLogo) {
//       formData.append('logo', company.logo);
//     }
//     if (company.banner !== initialBanner) {
//       formData.append('banner', company.banner);
//     }

//     try {
//       await axios.put(`https://emkayindia.in/api/v1/company/${companyId}`, formData, {
//         headers: {
//           Authorization: `Bearer ${token}`,
//           'Content-Type': 'multipart/form-data',
//         },
//       });
//       onSave();
//     } catch (error) {
//       console.error('Error updating company:', error);
//     }
//   };

//   return (
//     <div className="max-w-2xl mx-auto bg-white p-8 shadow-lg rounded-lg">
//       <h2 className="text-3xl font-semibold mb-6">Edit Company</h2>
//       <form onSubmit={handleSubmit} className="space-y-4">
//         <div>
//           <label className="block text-gray-700 font-medium">Company Name</label>
//           <input
//             type="text"
//             name="companyName"
//             value={company.companyName}
//             onChange={handleInputChange}
//             className="w-full px-4 py-2 border rounded-lg focus:ring focus:ring-blue-200"
//           />
//         </div>

//         <div>
//           <label className="block text-gray-700 font-medium">GST No.</label>
//           <input
//             type="text"
//             name="gstNo"
//             value={company.gstNo}
//             onChange={handleInputChange}
//             className="w-full px-4 py-2 border rounded-lg focus:ring focus:ring-blue-200"
//           />
//         </div>

//         <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
//           <div>
//             <label className="block text-gray-700 font-medium">Email</label>
//             <input
//               type="email"
//               name="email"
//               value={company.email}
//               onChange={handleInputChange}
//               className="w-full px-4 py-2 border rounded-lg focus:ring focus:ring-blue-200"
//             />
//           </div>
//           <div>
//             <label className="block text-gray-700 font-medium">Phone</label>
//             <input
//               type="text"
//               name="phone"
//               value={company.phone}
//               onChange={handleInputChange}
//               className="w-full px-4 py-2 border rounded-lg focus:ring focus:ring-blue-200"
//             />
//           </div>
//         </div>

//         <div>
//           <label className="block text-gray-700 font-medium">Address</label>
//           <textarea
//             name="address"
//             value={company.address}
//             onChange={handleInputChange}
//             className="w-full px-4 py-2 border rounded-lg focus:ring focus:ring-blue-200"
//           ></textarea>
//         </div>

//         <div>
//           <label className="block text-gray-700 font-medium">Terms and Conditions</label>
//           <textarea
//             name="termsAndConditions"
//             value={company.termsAndConditions.join('\n')}
//             onChange={(e) => setCompany({ ...company, termsAndConditions: e.target.value.split('\n') })}
//             className="w-full px-4 py-2 border rounded-lg focus:ring focus:ring-blue-200"
//           ></textarea>
//         </div>

//         <div>
//           <label className="block text-gray-700 font-medium">Logo</label>
//           <input
//             type="file"
//             name="logo"
//             onChange={handleImageChange}
//             className="w-full px-4 py-2 border rounded-lg focus:ring focus:ring-blue-200"
//           />
//           {initialLogo && !company.logo && (
//             <img src={initialLogo} alt="Current logo" className="mt-2 h-16 w-16 object-cover" />
//           )}
//         </div>

//         <div>
//           <label className="block text-gray-700 font-medium">Banner</label>
//           <input
//             type="file"
//             name="banner"
//             onChange={handleImageChange}
//             className="w-full px-4 py-2 border rounded-lg focus:ring focus:ring-blue-200"
//           />
//           {initialBanner && !company.banner && (
//             <img src={initialBanner} alt="Current banner" className="mt-2 h-24 w-full object-cover" />
//           )}
//         </div>

//         <div className="flex justify-end space-x-4">
//           <button
//             type="button"
//             onClick={onCancel}
//             className="px-4 py-2 text-gray-700 border rounded-lg hover:bg-gray-100"
//           >
//             Cancel
//           </button>
//           <button
//             type="submit"
//             className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
//           >
//             Save Changes
//           </button>
//         </div>
//       </form>
//     </div>
//   );
// };

// export default EditCompany;


// import React, { useState, useEffect } from 'react';
// import axios from 'axios';

// const EditCompany = ({ companyId, onSave, onCancel }) => {
//   const [company, setCompany] = useState({
//     companyName: '',
//     gstNo: '',
//     email: '',
//     phone: '',
//     address: '',
//     logo: null,
//     banner: null,
//     termsAndConditions: [''],
//     accountName: '',
//     bankName: '',
//     bankBranch: '',
//     bankAccount: '',
//     bankIFSC: '',
//   });
//   const [initialLogo, setInitialLogo] = useState(null);
//   const [initialBanner, setInitialBanner] = useState(null);

//   useEffect(() => {
//     const fetchCompanyDetails = async () => {
//       const token = localStorage.getItem('token');
//       try {
//         const response = await axios.get(`https://emkayindia.in/api/v1/company/get/${companyId}`, {
//           headers: { Authorization: `Bearer ${token}` },
//         });
//         const companyData = response.data;
//         setCompany({
//           ...companyData,
//           termsAndConditions: companyData.terms.termsAndConditions || [''],
//         });
//         setInitialLogo(companyData.logo);
//         setInitialBanner(companyData.banner);
//       } catch (error) {
//         console.error('Error fetching company details:', error);
//       }
//     };
//     fetchCompanyDetails();
//   }, [companyId]);

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setCompany({ ...company, [name]: value });
//   };

//   const handleImageChange = (e) => {
//     const { name, files } = e.target;
//     setCompany({ ...company, [name]: files[0] });
//   };

//   const handleTermsChange = (index, value) => {
//     const newTerms = [...company.termsAndConditions];
//     newTerms[index] = value;
//     setCompany({ ...company, termsAndConditions: newTerms });
//   };

//   const addTerm = () => {
//     setCompany({ ...company, termsAndConditions: [...company.termsAndConditions, ''] });
//   };

//   const removeTerm = (index) => {
//     const newTerms = company.termsAndConditions.filter((_, i) => i !== index);
//     setCompany({ ...company, termsAndConditions: newTerms });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     const token = localStorage.getItem('token');
//     const formData = new FormData();

//     formData.append('companyName', company.companyName);
//     formData.append('gstNo', company.gstNo);
//     formData.append('email', company.email);
//     formData.append('phone', company.phone);
//     formData.append('address', company.address);
//     formData.append('termsAndConditions', JSON.stringify(company.termsAndConditions));
//     formData.append('accountName', company.accountName);
//     formData.append('bankName', company.bankName);
//     formData.append('bankBranch', company.bankBranch);
//     formData.append('bankAccount', company.bankAccount);
//     formData.append('bankIFSC', company.bankIFSC);

//     // Only append the logo and banner if they've been changed
//     if (company.logo !== initialLogo) {
//       formData.append('logo', company.logo);
//     }
//     if (company.banner !== initialBanner) {
//       formData.append('banner', company.banner);
//     }

//     try {
//       await axios.put(`https://emkayindia.in/api/v1/company/update/${companyId}`, formData, {
//         headers: {
//           Authorization: `Bearer ${token}`,
//           'Content-Type': 'multipart/form-data',
//         },
//       });
//       onSave();
//     } catch (error) {
//       console.error('Error updating company:', error);
//     }
//   };

//   return (
//     <div className="max-w-3xl mx-auto bg-white p-8 shadow-lg rounded-lg">
//       <h2 className="text-3xl font-semibold mb-6">Edit Company</h2>
//       <form onSubmit={handleSubmit} className="space-y-6">

//         <div>
//           <label className="block text-gray-700 font-medium">Company Name</label>
//           <input
//             type="text"
//             name="companyName"
//             value={company.companyName}
//             onChange={handleInputChange}
//             className="w-full px-4 py-2 border rounded-lg focus:ring focus:ring-blue-200"
//           />
//         </div>

//         <div>
//           <label className="block text-gray-700 font-medium">GST No.</label>
//           <input
//             type="text"
//             name="gstNo"
//             value={company.gstNo}
//             onChange={handleInputChange}
//             className="w-full px-4 py-2 border rounded-lg focus:ring focus:ring-blue-200"
//           />
//         </div>

//         <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
//           <div>
//             <label className="block text-gray-700 font-medium">Email</label>
//             <input
//               type="email"
//               name="email"
//               value={company.email}
//               onChange={handleInputChange}
//               className="w-full px-4 py-2 border rounded-lg focus:ring focus:ring-blue-200"
//             />
//           </div>
//           <div>
//             <label className="block text-gray-700 font-medium">Phone</label>
//             <input
//               type="text"
//               name="phone"
//               value={company.phone}
//               onChange={handleInputChange}
//               className="w-full px-4 py-2 border rounded-lg focus:ring focus:ring-blue-200"
//             />
//           </div>
//         </div>

//         <div>
//           <label className="block text-gray-700 font-medium">Address</label>
//           <textarea
//             name="address"
//             value={company.address}
//             onChange={handleInputChange}
//             className="w-full px-4 py-2 border rounded-lg focus:ring focus:ring-blue-200"
//           ></textarea>
//         </div>

//         {/* Terms and Conditions - each term in a separate box */}
//         <div>
//           <label className="block text-gray-700 font-medium mb-2">Terms and Conditions</label>
//           {company.termsAndConditions.map((term, index) => (
//             <div key={index} className="flex items-center space-x-2 mb-2">
//               <input
//                 type="text"
//                 value={term}
//                 onChange={(e) => handleTermsChange(index, e.target.value)}
//                 className="w-full px-4 py-2 border rounded-lg focus:ring focus:ring-blue-200"
//               />
//               <button
//                 type="button"
//                 onClick={() => removeTerm(index)}
//                 className="text-red-500 font-semibold hover:text-red-700"
//               >
//                 Remove
//               </button>
//             </div>
//           ))}
//           <button
//             type="button"
//             onClick={addTerm}
//             className="px-4 py-2 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200"
//           >
//             Add Term
//           </button>
//         </div>

//         {/* Logo Upload with view option */}
//         <div>
//           <label className="block text-gray-700 font-medium">Logo</label>
//           <input
//             type="file"
//             name="logo"
//             onChange={handleImageChange}
//             className="w-full px-4 py-2 border rounded-lg focus:ring focus:ring-blue-200"
//           />
//           {initialLogo && !company.logo && (
//             <div className="mt-2">
//               <a href={initialLogo} target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">
//                 View Current Logo
//               </a>
//             </div>
//           )}
//         </div>

//         {/* Banner Upload with view option */}
//         <div>
//           <label className="block text-gray-700 font-medium">Banner</label>
//           <input
//             type="file"
//             name="banner"
//             onChange={handleImageChange}
//             className="w-full px-4 py-2 border rounded-lg focus:ring focus:ring-blue-200"
//           />
//           {initialBanner && !company.banner && (
//             <div className="mt-2">
//               <a href={initialBanner} target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">
//                 View Current Banner
//               </a>
//             </div>
//           )}
//         </div>

//         <div className="flex justify-end space-x-4">
//           <button
//             type="button"
//             onClick={onCancel}
//             className="px-4 py-2 text-gray-700 border rounded-lg hover:bg-gray-100"
//           >
//             Cancel
//           </button>
//           <button
//             type="submit"
//             className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
//           >
//             Save Changes
//           </button>
//         </div>
//       </form>
//     </div>
//   );
// };

// export default EditCompany;




// import React, { useState, useEffect } from 'react';
// import axios from 'axios';

// const EditCompany = ({ companyId, onSave, onCancel }) => {
//     const [company, setCompany] = useState({
//         companyName: '',
//         gstNo: '',
//         email: '',
//         phone: '',
//         address: '',
//         logo: null,
//         banner: null,
//         termsAndConditions: [''],
//         accountName: '',
//         bankName: '',
//         bankBranch: '',
//         bankAccount: '',
//         bankIFSC: '',
//     });
//     const [initialLogo, setInitialLogo] = useState(null);
//     const [initialBanner, setInitialBanner] = useState(null);

//     useEffect(() => {
//         const fetchCompanyDetails = async () => {
//             const token = localStorage.getItem('token');
//             try {
//                 const response = await axios.get(`https://emkayindia.in/api/v1/company/get/${companyId}`, {
//                     headers: { Authorization: `Bearer ${token}` },
//                 });
//                 const companyData = response.data;
//                 setCompany({
//                     ...companyData,
//                     termsAndConditions: companyData.terms.termsAndConditions || [''],
//                 });
//                 setInitialLogo(companyData.logo);
//                 setInitialBanner(companyData.banner);
//             } catch (error) {
//                 console.error('Error fetching company details:', error);
//             }
//         };
//         fetchCompanyDetails();
//     }, [companyId]);

//     const handleInputChange = (e) => {
//         const { name, value } = e.target;
//         setCompany({ ...company, [name]: value });
//     };

//     const handleImageChange = (e) => {
//         const { name, files } = e.target;
//         setCompany({ ...company, [name]: files[0] });
//     };

//     const handleTermsChange = (index, value) => {
//         const newTerms = [...company.termsAndConditions];
//         newTerms[index] = value;
//         setCompany({ ...company, termsAndConditions: newTerms });
//     };

//     const addTerm = () => {
//         setCompany({ ...company, termsAndConditions: [...company.termsAndConditions, ''] });
//     };

//     const removeTerm = (index) => {
//         const newTerms = company.termsAndConditions.filter((_, i) => i !== index);
//         setCompany({ ...company, termsAndConditions: newTerms });
//     };

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         const token = localStorage.getItem('token');
//         const formData = new FormData();

//         // Append text fields
//         formData.append('companyName', company.companyName);
//         formData.append('gstNo', company.gstNo);
//         formData.append('email', company.email);
//         formData.append('phone', company.phone);
//         formData.append('address', company.address);
//         formData.append('termsAndConditions', JSON.stringify(company.termsAndConditions));
//         formData.append('accountName', company.accountName);
//         formData.append('bankName', company.bankName);
//         formData.append('bankBranch', company.bankBranch);
//         formData.append('bankAccount', company.bankAccount);
//         formData.append('bankIFSC', company.bankIFSC);

//         // Only append the logo and banner if they've been changed
//         if (company.logo && company.logo !== initialLogo) {
//             formData.append('logo', company.logo);
//         }
//         if (company.banner && company.banner !== initialBanner) {
//             formData.append('banner', company.banner);
//         }

//         try {
//             await axios.put(`https://emkayindia.in/api/v1/company/update/${companyId}`, formData, {
//                 headers: {
//                     Authorization: `Bearer ${token}`,
//                     'Content-Type': 'multipart/form-data',
//                 },
//             });
//             onSave();
//         } catch (error) {
//             console.error('Error updating company:', error);
//         }
//     };

//     return (
//         <div className="mx-auto bg-white p-8 shadow-lg rounded-lg mt-[-1rem] w-[105%] mb-[-2rem]">
//             <h2 className="text-3xl font-semibold mb-6">Edit Company</h2>
//             <form onSubmit={handleSubmit} className="space-y-6">
//                 {/* Text fields for company details */}
//                 <div className='grid grid-cols-1 sm:grid-cols-2 gap-4'>
//                     <div >
//                         <label className="block text-gray-700 font-medium">Company Name</label>
//                         <input
//                             type="text"
//                             name="companyName"
//                             value={company.companyName}
//                             onChange={handleInputChange}
//                             className="w-full px-4 py-2 border rounded-lg focus:ring focus:ring-blue-200"
//                         />
//                     </div>

//                     <div>
//                         <label className="block text-gray-700 font-medium">GST No.</label>
//                         <input
//                             type="text"
//                             name="gstNo"
//                             value={company.gstNo}
//                             onChange={handleInputChange}
//                             className="w-full px-4 py-2 border rounded-lg focus:ring focus:ring-blue-200"
//                         />
//                     </div>
//                 </div>

//                 <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
//                     <div>
//                         <label className="block text-gray-700 font-medium">Email</label>
//                         <input
//                             type="email"
//                             name="email"
//                             value={company.email}
//                             onChange={handleInputChange}
//                             className="w-full px-4 py-2 border rounded-lg focus:ring focus:ring-blue-200"
//                         />
//                     </div>
//                     <div>
//                         <label className="block text-gray-700 font-medium">Phone</label>
//                         <input
//                             type="text"
//                             name="phone"
//                             value={company.phone}
//                             onChange={handleInputChange}
//                             className="w-full px-4 py-2 border rounded-lg focus:ring focus:ring-blue-200"
//                         />
//                     </div>
//                 </div>

//                 <div>
//                     <label className="block text-gray-700 font-medium">Address</label>
//                     <textarea
//                         name="address"
//                         value={company.address}
//                         onChange={handleInputChange}
//                         className="w-full px-4 py-2 border rounded-lg focus:ring focus:ring-blue-200"
//                     ></textarea>
//                 </div>

//                 {/* Terms and Conditions - each term in a separate box */}
//                 <div>
//                     <label className="block text-gray-700 font-medium mb-2">Terms and Conditions</label>
//                     {company.termsAndConditions.map((term, index) => (
//                         <div key={index} className="flex items-center space-x-2 mb-2">
//                             <input
//                                 type="text"
//                                 value={term}
//                                 onChange={(e) => handleTermsChange(index, e.target.value)}
//                                 className="w-full px-4 py-2 border rounded-lg focus:ring focus:ring-blue-200"
//                             />
//                             <button
//                                 type="button"
//                                 onClick={() => removeTerm(index)}
//                                 className="text-red-500 font-semibold hover:text-red-700"
//                             >
//                                 Remove
//                             </button>
//                         </div>
//                     ))}
//                     <button
//                         type="button"
//                         onClick={addTerm}
//                         className="px-4 py-2 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200"
//                     >
//                         Add Term
//                     </button>
//                 </div>

//                 {/* Logo Upload with view option */}
//                 <div>
//                     <label className="block text-gray-700 font-medium">Logo</label>
//                     <input
//                         type="file"
//                         name="logo"
//                         onChange={handleImageChange}
//                         className="w-full px-4 py-2 border rounded-lg focus:ring focus:ring-blue-200"
//                     />
//                     {initialLogo && !company.logo && (
//                         <div className="mt-2">
//                             <a href={initialLogo} target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">
//                                 View Current Logo
//                             </a>
//                         </div>
//                     )}
//                 </div>

//                 {/* Banner Upload with view option */}
//                 <div>
//                     <label className="block text-gray-700 font-medium">Banner</label>
//                     <input
//                         type="file"
//                         name="banner"
//                         onChange={handleImageChange}
//                         className="w-full px-4 py-2 border rounded-lg focus:ring focus:ring-blue-200"
//                     />
//                     {initialBanner && !company.banner && (
//                         <div className="mt-2">
//                             <a href={initialBanner} target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">
//                                 View Current Banner
//                             </a>
//                         </div>
//                     )}
//                 </div>

//                 <div className="flex justify-end space-x-4">
//                     <button
//                         type="button"
//                         onClick={onCancel}
//                         className="px-4 py-2 text-gray-700 border rounded-lg hover:bg-gray-100"
//                     >
//                         Cancel
//                     </button>
//                     <button
//                         type="submit"
//                         className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
//                     >
//                         Save Changes
//                     </button>
//                 </div>
//             </form>
//         </div>
//     );
// };

// export default EditCompany;



import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';

const EditCompany = ({ companyId, onSave, onCancel }) => {
  const [company, setCompany] = useState({
    companyName: '',
    gstNo: '',
    email: '',
    phone: '',
    address: '',
    logo: null,
    banner: null,
    termsAndConditions: [''],
    accountName: '',
    bankName: '',
    bankBranch: '',
    bankAccount: '',
    bankIFSC: '',
  });
  const [initialLogo, setInitialLogo] = useState(null);
  const [initialBanner, setInitialBanner] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadedUrl, setUploadedUrl] = useState(null);
  const [isUploaded, setIsUploaded] = useState(false);

  useEffect(() => {
    const fetchCompanyDetails = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await axios.get(`https://emkayindia.in/api/v1/company/get/${companyId}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        const companyData = response.data;
        setCompany({
          ...companyData,
          termsAndConditions: companyData.terms.termsAndConditions || [''],
        });
        setInitialLogo(companyData.logo);
        setInitialBanner(companyData.banner);
      } catch (error) {
        console.error('Error fetching company details:', error);
      }
    };
    fetchCompanyDetails();
  }, [companyId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCompany({ ...company, [name]: value });
  };

  const uploadImage = async (file) => {
    setIsUploading(true); // Start loading state
    const formData = new FormData();
    formData.append('image', file);
    const token = localStorage.getItem('token');

    try {
      const response = await axios.post('https://emkayindia.in/api/v1/image/upload', formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data'
        },
      });
      return response.data.url; // Return the uploaded image URL
    } catch (error) {
      console.error('Error uploading image:', error);
      toast.error('Error uploading image');
      return null;
    } finally {
      setIsUploading(false); // Stop loading state
    }
  };

  const handleImageChange = async (e, fieldName) => {
    const file = e.target.files[0];
    if (file) {
      const uploadedUrl = await uploadImage(file);
      if (uploadedUrl) {
        setCompany((prevCompany) => ({
          ...prevCompany,
          [fieldName]: uploadedUrl, // Update the specific field with the uploaded URL
        }));
        setIsUploaded(true); // Mark the image as successfully uploaded
      }
    }
  };

  const handleTermsChange = (index, value) => {
    const newTerms = [...company.termsAndConditions];
    newTerms[index] = value;
    setCompany({ ...company, termsAndConditions: newTerms });
  };

  const addTerm = () => {
    setCompany({ ...company, termsAndConditions: [...company.termsAndConditions, ''] });
  };

  const removeTerm = (index) => {
    const newTerms = company.termsAndConditions.filter((_, i) => i !== index);
    setCompany({ ...company, termsAndConditions: newTerms });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('token');
    const formData = new FormData();

    // Append text fields
    formData.append('companyName', company.companyName);
    formData.append('gstNo', company.gstNo);
    formData.append('email', company.email);
    formData.append('phone', company.phone);
    formData.append('address', company.address);
    formData.append('termsAndConditions', JSON.stringify(company.termsAndConditions));
    formData.append('accountName', company.accountName);
    formData.append('bankName', company.bankName);
    formData.append('bankBranch', company.bankBranch);
    formData.append('bankAccount', company.bankAccount);
    formData.append('bankIFSC', company.bankIFSC);

    // Only append the logo and banner if they've been changed
    if (company.logo && company.logo !== initialLogo) {
      formData.append('logo', company.logo);
    }
    if (company.banner && company.banner !== initialBanner) {
      formData.append('banner', company.banner);
    }
    console.log(company)

    try {
      await axios.patch(`https://emkayindia.in/api/v1/company/update/${companyId}`, company, {
        headers: {
          Authorization: `Bearer ${token}`,
        //   'Content-Type': 'multipart/form-data',
        },
      });
      console.log("Company Updated success");
      onSave();
    } catch (error) {
      console.error('Error updating company:', error);
    }
  };

  return (
    <div className="mx-auto bg-white p-8 shadow-lg rounded-lg mt-[-1rem] w-[105%] mb-[-2rem]">
      <h2 className="text-3xl font-semibold mb-6">Edit Company</h2>
      <form onSubmit={handleSubmit} className="space-y-6">
        {/* Text fields for company details */}
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <div>
            <label className="block text-gray-700 font-medium">Company Name</label>
            <input
              type="text"
              name="companyName"
              value={company.companyName}
              onChange={handleInputChange}
              className="w-full px-4 py-2 border rounded-lg focus:ring focus:ring-blue-200"
            />
          </div>

          <div>
            <label className="block text-gray-700 font-medium">GST No.</label>
            <input
              type="text"
              name="gstNo"
              value={company.gstNo}
              onChange={handleInputChange}
              className="w-full px-4 py-2 border rounded-lg focus:ring focus:ring-blue-200"
            />
          </div>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <div>
            <label className="block text-gray-700 font-medium">Email</label>
            <input
              type="email"
              name="email"
              value={company.email}
              onChange={handleInputChange}
              className="w-full px-4 py-2 border rounded-lg focus:ring focus:ring-blue-200"
            />
          </div>
          <div>
            <label className="block text-gray-700 font-medium">Phone</label>
            <input
              type="text"
              name="phone"
              value={company.phone}
              onChange={handleInputChange}
              className="w-full px-4 py-2 border rounded-lg focus:ring focus:ring-blue-200"
            />
          </div>
        </div>

        <div>
          <label className="block text-gray-700 font-medium">Address</label>
          <textarea
            name="address"
            value={company.address}
            onChange={handleInputChange}
            className="w-full px-4 py-2 border rounded-lg focus:ring focus:ring-blue-200"
          ></textarea>
        </div>

        {/* Terms and Conditions - each term in a separate box */}
        <div>
          <label className="block text-gray-700 font-medium mb-2">Terms and Conditions</label>
          {company.termsAndConditions.map((term, index) => (
            <div key={index} className="flex items-center space-x-2 mb-2">
              <input
                type="text"
                value={term}
                onChange={(e) => handleTermsChange(index, e.target.value)}
                className="w-full px-4 py-2 border rounded-lg focus:ring focus:ring-blue-200"
              />
              <button
                type="button"
                onClick={() => removeTerm(index)}
                className="text-red-500"
              >
                Remove
              </button>
            </div>
          ))}
          <button
            type="button"
            onClick={addTerm}
            className="px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600"
          >
            Add New Term
          </button>
        </div>

        {/* Logo and Banner Uploads */}
        <div>
          <label className="block text-gray-700 font-medium">Logo</label>
          <input
            type="file"
            onChange={(e) => handleImageChange(e, 'logo')}
            className="w-full px-4 py-2 border rounded-lg focus:ring focus:ring-blue-200"
          />
          {company.logo && (
            <div className="mt-2">
              <a
                href={company.logo}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500 underline"
              >
                View Current Logo
              </a>
            </div>
          )}
        </div>

        <div>
          <label className="block text-gray-700 font-medium">Banner</label>
          <input
            type="file"
            onChange={(e) => handleImageChange(e, 'banner')}
            className="w-full px-4 py-2 border rounded-lg focus:ring focus:ring-blue-200"
          />
          {company.banner && (
            <div className="mt-2">
              <a
                href={company.banner}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500 underline"
              >
                View Current Banner
              </a>
            </div>
          )}
        </div>

        {/* Submit and Cancel buttons */}
        <div className="flex justify-end space-x-4">
          <button
            type="button"
            onClick={onCancel}
            className="px-4 py-2 text-gray-700 border rounded-lg hover:bg-gray-100"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
          >
            Save Changes
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditCompany;
