import React, { useState } from 'react';
import './css/AddClient.css';
import axios from 'axios';
import { toast } from 'react-toastify';

const AddClient = ({ selectedCompany, toggleComponent }) => {
  const [clientData, setClientData] = useState({
    name: '',
    nickname: '',
    email: '',
    phone: '',
    address: '',
    gstNo: '',
    contactPerson: '',
    contactEmail: '',
    contactPhone: '',
    companyId: selectedCompany?selectedCompany.id:""
  });
  const [loading, setLoading] = useState(false);


  // Handle input changes
  const handleChange = (e) => {
    setClientData({
      ...clientData,
      [e.target.name]: e.target.value,

    });
  };

  // Handle company selection (multi-select)
  const handleCompanyChange = (e) => {
    const options = Array.from(e.target.selectedOptions, (option) => option.value);
    setClientData({ ...clientData, companyId: options });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    const token = localStorage.getItem('token');
    e.preventDefault();
    setLoading(true);
    console.log(clientData);
    try {
      const response = await axios.post(
        'https://emkayindia.in/api/v1/client/create',
        clientData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );
      // setSuccessMessage('Client created successfully!');
      // setError('');
      toast.success("Client Added");

      toggleComponent("CreateQuatation");


      console.log(response.data);
      setLoading(false);
    } catch (err) {
      // setError('Failed to create client. Please try again.');
      // console.error(err);
      toast.error("Error Occured while Adding Client "+err);
      setLoading(false);
    }
  };
  return (
    <div className="add-client-container">
      <h2 className="form-title">Add New Client</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-container-addclient">
          <div className="form-section">
            <label>Name:</label>
            <input type="text" name="name" value={clientData.name} onChange={handleChange} required />

            <label>Nickname:</label>
            <input type="text" name="nickname" value={clientData.nickname} onChange={handleChange} required />

            <label>Email:</label>
            <input type="email" name="email" value={clientData.email} onChange={handleChange} required />

            <label>Phone:</label>
            <input type="tel" name="phone" value={clientData.phone} onChange={handleChange} required />
          </div>

          <div className="form-section">
            <label>Address:</label>
            <input type="text" name="address" value={clientData.address} onChange={handleChange} required />

            <label>GST No:</label>
            <input type="text" name="gstNo" value={clientData.gstNo} onChange={handleChange} required />

            <label>Contact Person:</label>
            <input type="text" name="contactPerson" value={clientData.contactPerson} onChange={handleChange} required />

            <label>Contact Email:</label>
            <input type="email" name="contactEmail" value={clientData.contactEmail} onChange={handleChange} required />

          </div>
          <div className='form-section'>
            <label>Contact Phone:</label>
            <input type="tel" name="contactPhone" value={clientData.contactPhone} onChange={handleChange} required />

          </div>
        </div>

        <div className="selected-company">
          {selectedCompany ? (
            <label >Selected Company: <label className='text-green-500'>{selectedCompany.companyName}</label></label>
          ) : (
            <label className='text-red-500'>Select a company first</label>
          )}
        </div>
        <div className='flex justify-center align-middle'>
          {loading ? (
            <div className="loader"></div>  // Circular loader component
          ) : (
            <button className="submit-btn" type="submit" disabled={loading}>
              Create Client
            </button>
          )}
        </div>

      </form>
    </div>
  );
};

export default AddClient;
