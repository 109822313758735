import axios from 'axios';
import React, { useState } from 'react';
import { toast } from 'react-toastify';

function UploadImage() {
  const [isUploading, setIsUploading] = useState(false); // For tracking upload state
  const [uploadedUrl, setUploadedUrl] = useState(null); // For storing the uploaded image URL
  const [isUploaded, setIsUploaded] = useState(false); // To track if an image was successfully uploaded

  // Function to upload the image to the server
  const uploadImage = async (file) => {
    setIsUploading(true); // Start loading state
    const formData = new FormData();
    formData.append('image', file);
    const token = localStorage.getItem('token');

    try {
      const response = await axios.post('https://emkayindia.in/api/v1/image/upload', formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data'
        },
      });
      return response.data.url; // Return the uploaded image URL
    } catch (error) {
      console.error('Error uploading image:', error);
      toast.error('Error uploading image');
      return null;
    } finally {
      setIsUploading(false); // Stop loading state
    }
  };

  // Function to handle the image upload
  const handleImageChange = async (file) => {
    const uploadedUrl = await uploadImage(file);
    if (uploadedUrl) {
      setUploadedUrl(uploadedUrl); // Set the image URL
      setIsUploaded(true); // Mark the image as successfully uploaded
    }
  };

  // Function to copy the uploaded URL to the clipboard
  const copyToClipboard = () => {
    if (uploadedUrl) {
      navigator.clipboard.writeText(uploadedUrl);
      toast.success('URL copied to clipboard!');
    }
  };

  // Reset the component for a new image upload
  const uploadAnotherImage = () => {
    setUploadedUrl(null); // Clear the uploaded URL
    setIsUploaded(false); // Reset the uploaded state
  };

  return (
    <div className="max-w-lg mx-auto p-6 bg-white shadow-lg rounded-lg  ml-[40%] mt-14">
      <h3 className="text-xl font-semibold mb-4">Upload Image</h3>
      <p className='mb-4'>
        **This should be used in case of bulk product add using Excel
      </p>

      {!isUploaded ? (
        <>
          <label className="block mb-4">
            <span className="text-gray-700">Select Image:</span>
            <input
              type="file"
              onChange={e => handleImageChange(e.target.files[0])}
              required // Require at least the first image field
              className="block w-full mt-2 p-2 border border-gray-300 rounded-md"
            />
          </label>
          {isUploading && <p className="text-blue-600">Uploading...</p>}
        </>
      ) : (
        <div>
          <p className="text-gray-700 mb-2">Uploaded Image URL:</p>
          <div className="flex items-center">
            <input
              type="text"
              value={uploadedUrl}
              readOnly
              className="w-full p-2 border border-gray-300 rounded-md mr-4"
            />
            <button
              onClick={copyToClipboard}
              className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-md"
            >
              Copy URL
            </button>
          </div>
          <button
            onClick={uploadAnotherImage}
            className="mt-4 bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded-md"
          >
            Upload Another Image
          </button>
        </div>
      )}
    </div>
  );
}

export default UploadImage;
