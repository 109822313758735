// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import './css/AllClient.css';

// const AllClients = () => {
//   const [clients, setClients] = useState([]);
//   const [selectedClient, setSelectedClient] = useState(null);
//   const [searchQuery, setSearchQuery] = useState('');

//   useEffect(() => {
//     const fetchClients = async () => {
//       const token = localStorage.getItem('token'); // Retrieve the token from localStorage
//       try {
//         const response = await axios.get('https://emkayindia.in/api/v1/client/all', {
//           headers: {
//             Authorization: `Bearer ${token}`, // Add the token to the request headers
//           },
//         });
//         setClients(response.data); // Assuming the response is an array of clients
//       } catch (error) {
//         console.error('Error fetching clients', error);
//       }
//     };

//     fetchClients();
//   }, []);

//   const handleClientClick = (client) => {
//     setSelectedClient(client); // Set the clicked client as the selected one
//   };

//   const handleSearch = (event) => {
//     setSearchQuery(event.target.value);
//   };

//   // Filter clients based on search query
//   const filteredClients = clients.filter(
//     (client) =>
//       client.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
//       client.nickname.toLowerCase().includes(searchQuery.toLowerCase()) ||
//       client.id.toString().includes(searchQuery)
//   );

//   return (
//     <div className="all-client-container p-4 w-[83%] ml-64 mt-14 ">
//       {!selectedClient ? (
//         <div>
//           <h2 className="text-2xl font-bold mb-4">All Clients</h2>

//           {/* Search Input */}
//           <div className="mb-6 mt-6 w-[50%] flex justify-center align-middle">
//             <label className='font-medium font-sans'>Search Client Name:</label>
//             <input
//               type="text"
//               value={searchQuery}
//               onChange={handleSearch}
//               placeholder="Search clients..."
//               className="w-[75%] px-4 py-2 border rounded-lg shadow-md focus:outline-none focus:ring focus:border-blue-300"
//             />
//           </div>

//           {/* Clients Table */}
//           <div className="overflow-x-auto">
//             <table className="min-w-full table-auto border-collapse bg-white shadow-md rounded-lg">
//               <thead className="bg-gray-200">
//                 <tr>
//                   <th className="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">ID</th>
//                   <th className="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">Nickname</th>
//                   <th className="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">Name</th>
//                 </tr>
//               </thead>
//               <tbody className="bg-white divide-y divide-gray-200">
//                 {filteredClients.length > 0 ? (
//                   filteredClients.map((client) => (
//                     <tr
//                       key={client.id}
//                       className="hover:bg-gray-100 cursor-pointer transition duration-200"
//                       onClick={() => handleClientClick(client)}
//                     >
//                       <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{client.id}</td>
//                       <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{client.nickname}</td>
//                       <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{client.name}</td>
//                     </tr>
//                   ))
//                 ) : (
//                   <tr>
//                     <td colSpan="3" className="text-center py-4 text-gray-500">No clients found</td>
//                   </tr>
//                 )}
//               </tbody>
//             </table>
//           </div>
//         </div>
//       ) : (
//         <div>
//           <button
//             style={{ backgroundColor: '#343a40' }} // Custom background color
//             className="text-white font-semibold py-2 px-4 rounded-lg shadow-lg transition duration-300 ease-in-out hover:white absolute right-5"
//             onClick={() => setSelectedClient(null)}
//           >
//             Back to All Clients
//           </button>
//           <h2 className="text-2xl font-bold mb-4">Client Details</h2>
//           {selectedClient && (
//             <div className="client-details bg-white p-6 shadow-md rounded-lg">
//               <p className="text-gray-700"><strong>Name:</strong> {selectedClient.name}</p>
//               <p className="text-gray-700"><strong>Nickname:</strong> {selectedClient.nickname}</p>
//               <p className="text-gray-700"><strong>Email:</strong> {selectedClient.email}</p>
//               <p className="text-gray-700"><strong>GST No.:</strong> {selectedClient.gstNo}</p>
//               <p className="text-gray-700"><strong>Phone:</strong> {selectedClient.phone}</p>
//               <p className="text-gray-700"><strong>Address:</strong> {selectedClient.address}</p>
//               <p className="text-gray-700"><strong>Contact Person:</strong> {selectedClient.contactPerson}</p>
//             </div>
//           )}
          
//           <h2 className="text-2xl font-bold mt-4 mb-2">Quotations</h2>
//           {selectedClient.quotation && selectedClient.quotation.length > 0 ? (
//             <div className="overflow-x-auto">
//               <table className="min-w-full table-auto border-collapse bg-white shadow-md rounded-lg">
//                 <thead className="bg-gray-200">
//                   <tr>
//                     <th className="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">Quotation ID</th>
//                     <th className="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">Date</th>
//                     <th className="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">Company Id</th>
//                   </tr>
//                 </thead>
//                 <tbody className="bg-white divide-y divide-gray-200">
//                   {selectedClient.quotation.map((quotation) => (
//                     <tr key={quotation.id}>
//                       <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{quotation.id}</td>
//                       <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{new Date(quotation.date).toLocaleDateString()}</td>
//                       <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{quotation.companyId}</td>
//                     </tr>
//                   ))}
//                 </tbody>
//               </table>
//             </div>
//           ) : (
//             <p className="text-gray-500">No quotations available for this client.</p>
//           )}
//         </div>
//       )}
//     </div>
//   );
// };

// export default AllClients;


import React, { useEffect, useState } from 'react';
import axios from 'axios';
import EditUser from '../components/editComponent/EditUser'; // Import the EditUser component
import './css/AllClient.css';

const AllClients = () => {
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [isEditing, setIsEditing] = useState(false); // State to control edit mode

  useEffect(() => {
    const fetchClients = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await axios.get('https://emkayindia.in/api/v1/client/all', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setClients(response.data);
      } catch (error) {
        console.error('Error fetching clients', error);
      }
    };

    fetchClients();
  }, []);

  const handleClientClick = (client) => {
    setSelectedClient(client);
    setIsEditing(false); // Ensure edit mode is off when selecting a client
  };

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleEditClick = () => {
    setIsEditing(true); // Enable edit mode
  };

  const handleUserUpdate = (updatedUser) => {
    setClients(prevClients => 
      prevClients.map(client => client.id === updatedUser.id ? updatedUser : client)
    );
    setIsEditing(false);
    setSelectedClient(updatedUser); // Update the displayed details after edit
  };

  const filteredClients = clients.filter(
    (client) =>
      client.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      client.nickname.toLowerCase().includes(searchQuery.toLowerCase()) ||
      client.id.toString().includes(searchQuery)
  );

  return (
    <div className="all-client-container p-4 w-[83%] ml-64 mt-14">
      {!selectedClient ? (
        <div>
          <h2 className="text-2xl font-bold mb-4">All Clients</h2>

          {/* Search Input */}
          <div className="mb-6 mt-6 w-[50%] flex justify-center align-middle">
            <label className='font-medium font-sans'>Search Client Name:</label>
            <input
              type="text"
              value={searchQuery}
              onChange={handleSearch}
              placeholder="Search clients..."
              className="w-[75%] px-4 py-2 border rounded-lg shadow-md focus:outline-none focus:ring focus:border-blue-300"
            />
          </div>

          {/* Clients Table */}
          <div className="overflow-x-auto">
            <table className="min-w-full table-auto border-collapse bg-white shadow-md rounded-lg">
              <thead className="bg-gray-200">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">ID</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">Nickname</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">Name</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {filteredClients.length > 0 ? (
                  filteredClients.map((client) => (
                    <tr
                      key={client.id}
                      className="hover:bg-gray-100 cursor-pointer transition duration-200"
                      onClick={() => handleClientClick(client)}
                    >
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{client.id}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{client.nickname}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{client.name}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="3" className="text-center py-4 text-gray-500">No clients found</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      ) : isEditing ? (
        <EditUser
          user={selectedClient}
          onSave={handleUserUpdate}
          onCancel={() => setIsEditing(false)}
        />
      ) : (
        <div>
          <button
            style={{ backgroundColor: '#343a40' }}
            className="text-white font-semibold py-2 px-4 rounded-lg shadow-lg transition duration-300 ease-in-out hover:white absolute right-5"
            onClick={() => setSelectedClient(null)}
          >
            Back to All Clients
          </button>
          <h2 className="text-2xl font-bold mb-4">Client Details</h2>
          {selectedClient && (
            <div className="client-details bg-white p-6 shadow-md rounded-lg">
              <p className="text-gray-700"><strong>Name:</strong> {selectedClient.name}</p>
              <p className="text-gray-700"><strong>Nickname:</strong> {selectedClient.nickname}</p>
              <p className="text-gray-700"><strong>Email:</strong> {selectedClient.email}</p>
              <p className="text-gray-700"><strong>GST No.:</strong> {selectedClient.gstNo}</p>
              <p className="text-gray-700"><strong>Phone:</strong> {selectedClient.phone}</p>
              <p className="text-gray-700"><strong>Address:</strong> {selectedClient.address}</p>
              <p className="text-gray-700"><strong>Contact Person:</strong> {selectedClient.contactPerson}</p>
              <button
                onClick={handleEditClick}
                className="mt-4 bg-blue-500 text-white px-4 py-1 rounded hover:bg-blue-600"
              >
                Edit User
              </button>
            </div>
          )}
          
          <h2 className="text-2xl font-bold mt-4 mb-2">Quotations</h2>
          {selectedClient.quotation && selectedClient.quotation.length > 0 ? (
            <div className="overflow-x-auto">
              <table className="min-w-full table-auto border-collapse bg-white shadow-md rounded-lg">
                <thead className="bg-gray-200">
                  <tr>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">Quotation ID</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">Date</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-600 uppercase tracking-wider">Company Id</th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {selectedClient.quotation.map((quotation) => (
                    <tr key={quotation.id}>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{quotation.id}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{new Date(quotation.date).toLocaleDateString()}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{quotation.companyId}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <p className="text-gray-500">No quotations available for this client.</p>
          )}
        </div>
      )}
    </div>
  );
};

export default AllClients;
