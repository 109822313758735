// import axios from 'axios';
// import React, { useState, useEffect } from 'react';

// const EditUser = ({ user, onSave, onCancel }) => {
//     const [formData, setFormData] = useState({
//         email: '',
//         name: '',
//         password: '',
//         role: '',
//         permission: [],
//         companies: [],
//         address: '',
//         city: '',
//         phone: '',
//         pincode: ''
//     });

//     useEffect(() => {
//         if (user) {
//             setFormData({
//                 email: user.email || '',
//                 name: user.name || '',
//                 password: '',
//                 role: user.role || '',
//                 permission: user.permission || [],
//                 companies: user.companies || [],
//                 address: user.address || '',
//                 city: user.city || '',
//                 phone: user.phone || '',
//                 pincode: user.pincode || ''
//             });
//         }
//     }, [user]);

//     const [allPermissions, setAllPermissions] = useState([]);
//     const [allCompanies, setAllCompanies] = useState([]);

//     useEffect(() => {
//         const token = localStorage.getItem('token');

//         // Fetch permissions
//         axios.get('https://emkayindia.in/api/v1/permission/all', {
//             headers: { Authorization: `Bearer ${token}` }
//         })
//             .then(response => setAllPermissions(response.data))
//             .catch(error => console.error('Error fetching permissions:', error));

//         // Fetch companies
//         axios.get('https://emkayindia.in/api/v1/company/all', {
//             headers: { Authorization: `Bearer ${token}` }
//         })
//             .then(response => setAllCompanies(response.data))
//             .catch(error => console.error('Error fetching companies:', error));
//     }, []);


//     const handleCheckboxChange = (event, type) => {
//         const value = event.target.value;
//         const checked = event.target.checked;

//         setFormData(prevFormData => ({
//             ...prevFormData,
//             [type]: checked
//                 ? [...prevFormData[type], value]
//                 : prevFormData[type].filter(item => item !== value)
//         }));
//     };


//     const handleChange = (e) => {
//         const { name, value } = e.target;
//         setFormData((prevData) => ({
//             ...prevData,
//             [name]: value
//         }));
//     };

//     const handleArrayChange = (e, field) => {
//         const values = e.target.value.split(',').map((item) => item.trim());
//         setFormData((prevData) => ({
//             ...prevData,
//             [field]: values
//         }));
//     };

//     const handleSubmit = (e) => {
//         e.preventDefault();
//         onSave({ ...user, ...formData });
//     };

//     return (
//         <div className="p-4 bg-white rounded shadow-md w-full">
//             <h2 className="text-2xl font-semibold mb-4">Edit User</h2>
//             <form onSubmit={handleSubmit}>
//                 <label className="block mb-2">
//                     Email:
//                     <input
//                         type="email"
//                         name="email"
//                         value={formData.email}
//                         onChange={handleChange}
//                         className="w-full p-2 border border-gray-300 rounded-md"
//                         required
//                     />
//                 </label>

//                 <label className="block mb-2">
//                     Name:
//                     <input
//                         type="text"
//                         name="name"
//                         value={formData.name}
//                         onChange={handleChange}
//                         className="w-full p-2 border border-gray-300 rounded-md"
//                         required
//                     />
//                 </label>


//                 <label className="block mb-2">
//                     Role:
//                     <select
//                         name="role"
//                         value={formData.role}
//                         onChange={handleChange}
//                         className="w-full p-2 border border-gray-300 rounded-md"
//                         required
//                     >
//                         <option value="USER">USER</option>
//                         <option value="ADMIN">ADMIN</option>
//                         <option value="SUPERADMIN">SUPERADMIN</option>
//                     </select>
//                 </label>
//                 <label className="block mb-2">Permissions:</label>
//                 <div className="border p-2 rounded-md">
//                     {allPermissions.map(permission => (
//                         <label key={permission.id} className="block">
//                             <input
//                                 type="checkbox"
//                                 value={permission.id}
//                                 checked={formData.permission.includes(permission.id)}
//                                 onChange={(e) => handleCheckboxChange(e, 'permission')}
//                             />
//                             {permission.name}
//                         </label>
//                     ))}
//                 </div>

//                 <label className="block mb-2 mt-4">Companies:</label>
//                 <div className="border p-2 rounded-md">
//                     {allCompanies.map(company => (
//                         <label key={company.id} className="block">
//                             <input
//                                 type="checkbox"
//                                 value={company.id}
//                                 checked={formData.companies.includes(company.id)}
//                                 onChange={(e) => handleCheckboxChange(e, 'companies')}
//                             />
//                             {company.companyName}
//                         </label>
//                     ))}
//                 </div>

//                 <label className="block mb-2">
//                     Address:
//                     <input
//                         type="text"
//                         name="address"
//                         value={formData.address}
//                         onChange={handleChange}
//                         className="w-full p-2 border border-gray-300 rounded-md"
//                     />
//                 </label>

//                 <label className="block mb-2">
//                     City:
//                     <input
//                         type="text"
//                         name="city"
//                         value={formData.city}
//                         onChange={handleChange}
//                         className="w-full p-2 border border-gray-300 rounded-md"
//                     />
//                 </label>

//                 <label className="block mb-2">
//                     Phone:
//                     <input
//                         type="text"
//                         name="phone"
//                         value={formData.phone}
//                         onChange={handleChange}
//                         className="w-full p-2 border border-gray-300 rounded-md"
//                     />
//                 </label>

//                 <label className="block mb-2">
//                     Pincode:
//                     <input
//                         type="text"
//                         name="pincode"
//                         value={formData.pincode}
//                         onChange={handleChange}
//                         className="w-full p-2 border border-gray-300 rounded-md"
//                     />
//                 </label>

//                 <div className="mt-4">
//                     <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 mr-2">
//                         Save
//                     </button>
//                     <button onClick={onCancel} className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600">
//                         Cancel
//                     </button>
//                 </div>
//             </form>
//         </div>
//     );
// };

// export default EditUser;


// import axios from 'axios';
// import React, { useState, useEffect } from 'react';

// const EditUser = ({ userId, onSave, onCancel }) => {
//     const [formData, setFormData] = useState({
//         email: '',
//         name: '',
//         password: '',
//         role: '',
//         permission: [],
//         companies: [],
//         address: '',
//         city: '',
//         phone: '',
//         pincode: ''
//     });

//     const [allPermissions, setAllPermissions] = useState([]);
//     const [allCompanies, setAllCompanies] = useState([]);

//     useEffect(() => {
//         if (user) {
//             console.log(user);
//             setFormData({
//                 email: user.email || '',
//                 name: user.name || '',
//                 password: '',
//                 role: user.role || '',
//                 permission: user.permission || [],
//                 companies: user.companies || [],
//                 address: user.address || '',
//                 city: user.city || '',
//                 phone: user.phone || '',
//                 pincode: user.pincode || ''
//             });
//         }
//     }, [user]);

//     useEffect(() => {
//         const token = localStorage.getItem('token');

//         // Fetch permissions and companies with authentication
//         axios.get('https://emkayindia.in/api/v1/permission/all', {
//             headers: { Authorization: `Bearer ${token}` }
//         })
//             .then(response => setAllPermissions(response.data))
//             .catch(error => console.error('Error fetching permissions:', error));

//         axios.get('https://emkayindia.in/api/v1/company/all', {
//             headers: { Authorization: `Bearer ${token}` }
//         })
//             .then(response => setAllCompanies(response.data))
//             .catch(error => console.error('Error fetching companies:', error));
//     }, []);

//     const handleCheckboxChange = (event, type) => {
//         const value = event.target.value;
//         const checked = event.target.checked;

//         setFormData(prevFormData => ({
//             ...prevFormData,
//             [type]: checked
//                 ? [...prevFormData[type], value]
//                 : prevFormData[type].filter(item => item !== value)
//         }));
//     };

//     const handleChange = (e) => {
//         const { name, value } = e.target;
//         setFormData((prevData) => ({
//             ...prevData,
//             [name]: value
//         }));
//     };

//     const handleSubmit = (e) => {
//         e.preventDefault();
//         onSave({ ...user, ...formData });
//     };

//     return (
//         <div className="p-6 bg-white rounded-md shadow-lg w-full max-w-2xl mx-auto">
//             <h2 className="text-2xl font-semibold mb-6 text-gray-800">Edit User</h2>
//             <form onSubmit={handleSubmit}>
//                 <div className="mb-4">
//                     <label className="text-gray-700">Email:</label>
//                     <input
//                         type="email"
//                         name="email"
//                         value={formData.email}
//                         onChange={handleChange}
//                         className="w-full mt-1 p-2 border border-gray-300 rounded-md"
//                         required
//                     />
//                 </div>

//                 <div className="mb-4">
//                     <label className="text-gray-700">Name:</label>
//                     <input
//                         type="text"
//                         name="name"
//                         value={formData.name}
//                         onChange={handleChange}
//                         className="w-full mt-1 p-2 border border-gray-300 rounded-md"
//                         required
//                     />
//                 </div>

//                 <div className="mb-4">
//                     <label className="text-gray-700">Role:</label>
//                     <select
//                         name="role"
//                         value={formData.role}
//                         onChange={handleChange}
//                         className="w-full mt-1 p-2 border border-gray-300 rounded-md"
//                         required
//                     >
//                         <option value="USER">USER</option>
//                         <option value="ADMIN">ADMIN</option>
//                         <option value="SUPERADMIN">SUPERADMIN</option>
//                     </select>
//                 </div>

//                 <div className="mb-4">
//                     <label className="text-gray-700">Permissions:</label>
//                     <div className="border p-3 rounded-md mt-2 bg-gray-50">
//                         {allPermissions.map(permission => (
//                             <label key={permission.id} className="block text-gray-700">
//                                 <input
//                                     type="checkbox"
//                                     value={permission.id}
//                                     checked={formData.permission.includes(permission.id)}
//                                     onChange={(e) => handleCheckboxChange(e, 'permission')}
//                                     className="mr-2"
//                                 />
//                                 {permission.name}
//                             </label>
//                         ))}
//                     </div>
//                 </div>

//                 <div className="mb-4">
//                     <label className="text-gray-700">Companies:</label>
//                     <div className="border p-3 rounded-md mt-2 bg-gray-50">
//                         {allCompanies.map(company => (
//                             <label key={company.id} className="block text-gray-700">
//                                 <input
//                                     type="checkbox"
//                                     value={company.id}
//                                     checked={formData.companies.includes(company.id)}
//                                     onChange={(e) => handleCheckboxChange(e, 'companies')}
//                                     className="mr-2"
//                                 />
//                                 {company.companyName}
//                             </label>
//                         ))}
//                     </div>
//                 </div>

//                 <div className="grid grid-cols-2 gap-4">
//                     <div className="mb-4">
//                         <label className="text-gray-700">Address:</label>
//                         <input
//                             type="text"
//                             name="address"
//                             value={formData.address}
//                             onChange={handleChange}
//                             className="w-full mt-1 p-2 border border-gray-300 rounded-md"
//                         />
//                     </div>

//                     <div className="mb-4">
//                         <label className="text-gray-700">City:</label>
//                         <input
//                             type="text"
//                             name="city"
//                             value={formData.city}
//                             onChange={handleChange}
//                             className="w-full mt-1 p-2 border border-gray-300 rounded-md"
//                         />
//                     </div>
//                 </div>

//                 <div className="grid grid-cols-2 gap-4">
//                     <div className="mb-4">
//                         <label className="text-gray-700">Phone:</label>
//                         <input
//                             type="text"
//                             name="phone"
//                             value={formData.phone}
//                             onChange={handleChange}
//                             className="w-full mt-1 p-2 border border-gray-300 rounded-md"
//                         />
//                     </div>

//                     <div className="mb-4">
//                         <label className="text-gray-700">Pincode:</label>
//                         <input
//                             type="text"
//                             name="pincode"
//                             value={formData.pincode}
//                             onChange={handleChange}
//                             className="w-full mt-1 p-2 border border-gray-300 rounded-md"
//                         />
//                     </div>
//                 </div>

//                 <div className="mt-6 flex justify-end">
//                     <button
//                         type="submit"
//                         className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 mr-2"
//                     >
//                         Save
//                     </button>
//                     <button
//                         onClick={onCancel}
//                         className="bg-gray-500 text-white px-4 py-2 rounded-md hover:bg-gray-600"
//                     >
//                         Cancel
//                     </button>
//                 </div>
//             </form>
//         </div>
//     );
// };

// export default EditUser;


import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

const EditUser = ({ userId, onSave, onCancel }) => {
    const [formData, setFormData] = useState({
        email: '',
        name: '',
        password: '',
        role: '',
        permission: [],
        companies: [],
        address: '',
        city: '',
        phone: '',
        pincode: ''
    });

    const [allPermissions, setAllPermissions] = useState([]);
    const [allCompanies, setAllCompanies] = useState([]);

    useEffect(() => {
        const token = localStorage.getItem('token');

        // Fetch user data
        if (userId) {
            axios.get(`https://emkayindia.in/api/v1/user/get/${userId}`, {
                headers: { Authorization: `Bearer ${token}` }
            })
                .then(response => {
                    const user = response.data;
                    console.log(user);
                    setFormData({
                        email: user.email || '',
                        name: user.name || '',
                        role: user.role || '',
                        permission: user.permission || [],
                        companies: user.company || [],
                        address: user.address || '',
                        city: user.city || '',
                        phone: user.phone || '',
                        pincode: user.pincode || ''
                    });
                })
                .catch(error => console.error('Error fetching user data:', error));
        }

        // Fetch permissions and companies
        axios.get('https://emkayindia.in/api/v1/permission/all', {
            headers: { Authorization: `Bearer ${token}` }
        })
            .then(response => setAllPermissions(response.data))
            .catch(error => console.error('Error fetching permissions:', error));

        axios.get('https://emkayindia.in/api/v1/company/all', {
            headers: { Authorization: `Bearer ${token}` }
        })
            .then(response => setAllCompanies(response.data))
            .catch(error => console.error('Error fetching companies:', error));
    }, [userId]);

    const handleCheckboxChange = (event, type) => {
        const value = event.target.value;
        const checked = event.target.checked;

        setFormData(prevFormData => ({
            ...prevFormData,
            [type]: checked
                ? [...prevFormData[type], value]
                : prevFormData[type].filter(item => item !== value)
        }));
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const token = localStorage.getItem('token'); // Get the token from localStorage

        const formDataToSend = {
            ...formData,
            permission: formData.permission.map(p => p.id),
            companies: formData.companies.map(c => c.id)
        };
        console.log(formDataToSend);
        axios.patch(`https://emkayindia.in/api/v1/user/update/${userId}`, formDataToSend, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        })
            .then(response => {
                toast.success("User Updated");
                console.log('User updated successfully', response.data);
                // Optionally, reset the form or show success message
            })
            .catch(error => {
                console.error('Error updating user:', error);
                // Optionally, show an error message
            });
        // onSave({ userId, ...formData });
    };
    const handlePermissionChange = (e) => {
        const { value, checked } = e.target;
        const permissionId = String(value);  // Ensure it's a string
    
        console.log("Permission ID: " + permissionId);  // Check if it's a string
    
        setFormData((prevFormData) => {
            let updatedPermissions;
    
            if (checked) {
                // Add the permissionId to the array if it's not already present
                updatedPermissions = prevFormData.permission.includes(permissionId)
                    ? prevFormData.permission  // If already exists, don't add again
                    : [...prevFormData.permission, {id:permissionId}];  // Add permissionId if not present
                    console.log(...prevFormData.permission);
            } else {
                // Remove the permissionId from the array
                updatedPermissions = prevFormData.permission.filter(item => item !== permissionId);
            }
    
            return {
                ...prevFormData,
                permission: updatedPermissions,  // Update the permission array
            };
        });
    };
    
    const handleCompanyChange = (e) => {
        const { value, checked } = e.target;  // `value` is the company `id`
        const companyId = String(value);  // Ensure it's a string
        setFormData((prevFormData) => {
            let updatedCompanies;
            console.log("Checked "+ checked)
            if (checked) {
                // Add the companyId to the array if it's not already present
                updatedCompanies = prevFormData.companies.includes(companyId)
                    ? prevFormData.companies  // If already exists, don't add again
                    : [...prevFormData.companies, {id:companyId}]; 
                    console.log(...prevFormData.companies) // Add companyId if not present
            } else {
                // Remove the companyId from the array
                updatedCompanies = prevFormData.companies.filter(item => item !== companyId);
            }
    
            return {
                ...prevFormData,
                companies: updatedCompanies, 
            };
        });
    };
    


    return (
        <div className="p-6 bg-white  w-[100%]  mx-auto mt-[-1rem] ml-[-1.5rem]">
            <h2 className="text-2xl font-semibold mb-6 text-gray-800">Edit User</h2>
            <form onSubmit={handleSubmit}>
                {/* Form Fields */}
                <div className='flex justify-start' >

                    <div className="mb-4 mr-[10%]">
                        <label className="text-gray-700 ">Email:</label>
                        <input
                            type="text"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            className="w-full mt-1 p-2 border border-gray-300 rounded-md"
                            required
                        />
                    </div>

                    <div className="mb-4 mr-[10%]">
                        <label className="text-gray-700">Name:</label>
                        <input
                            type="text"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            className="w-full mt-1 p-2 border border-gray-300 rounded-md"
                            required
                        />
                    </div>
                    <div className="mb-4 mr-[10%] w-[25%]">
                        <label className="text-gray-700">Role:</label>
                        <select
                            name="role"
                            value={formData.role}
                            onChange={handleChange}
                            className="w-full mt-1 p-2 border border-gray-300 rounded-md"
                            required
                        >
                            <option value="USER">USER</option>
                            <option value="ADMIN">ADMIN</option>
                            <option value="SUPERADMIN">SUPERADMIN</option>
                        </select>
                    </div>
                </div>
                <div className="flex justify-start">
                    <div className="mb-4  mr-[10%] w-full">
                        <label className="text-gray-700">Phone:</label>
                        <input
                            type="text"
                            name="phone"
                            value={formData.phone}
                            onChange={handleChange}
                            className="w-full mt-1 p-2 border border-gray-300 rounded-md"
                        />
                    </div>
                    <div className="mb-4 mr-[10%] w-full">
                        <label className="text-gray-700">City:</label>
                        <input
                            type="text"
                            name="city"
                            value={formData.city}
                            onChange={handleChange}
                            className="w-full mt-1 p-2 border border-gray-300 rounded-md"
                        />
                    </div>
                    <div className="mb-4 mr-[10%] w-full">
                        <label className="text-gray-700">Pincode:</label>
                        <input
                            type="text"
                            name="pincode"
                            value={formData.pincode}
                            onChange={handleChange}
                            className="w-full mt-1 p-2 border border-gray-300 rounded-md"
                        />
                    </div>
                </div>
                <div className="w-[90%] ">
                    <div className="mb-4 w-full">
                        <label className="text-gray-700">Address:</label>
                        <input
                            type="text"
                            name="address"
                            value={formData.address}
                            onChange={handleChange}
                            className="w-full mt-1 p-2 border border-gray-300 rounded-md"
                        />
                    </div>


                </div>
                {/* <div className="mb-4">
                    <label className="text-gray-700">Permissions:</label>
                    <div className="border p-3 rounded-md mt-2 bg-gray-50">
                        {allPermissions.map(permission => (
                            <label key={permission.id} className="block text-gray-700">
                                <input
                                    type="checkbox"
                                    value={permission}
                                    // Check if the permission ID exists in formData.permission
                                    checked={formData.permission.some(p => p.id === permission.id)}
                                    onChange={(e) => handleCheckboxChange(e, 'permission')}
                                    className="mr-2"
                                />
                                {permission.name}
                            </label>
                        ))}
                    </div>
                </div>
                <div className="mb-4">
                    <label className="text-gray-700">Companies:</label>
                    <div className="border p-3 rounded-md mt-2 bg-gray-50">
                        {allCompanies.map(company => (
                            <label key={company.id} className="block text-gray-700">
                                <input
                                    type="checkbox"
                                    value={company.id}
                                    checked={formData.companies.some(c => c.id === company.id)}
                                    onChange={(e) => handleCheckboxChange(e, 'companies')}
                                    className="mr-2"
                                />
                                {company.companyName}
                            </label>
                        ))}
                    </div>
                </div> */}

                <div className="mb-4 ">
                    <label className="text-gray-700">Permissions:</label>
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 border p-3 rounded-md mt-2 bg-gray-50 border p-3 rounded-md mt-2 bg-gray-50">
                        {allPermissions.map(permission => (
                            <label key={permission.id} className="block text-gray-700">
                                <input
                                    type="checkbox"
                                    value={permission.id}  // Pass only the `id` of the permission
                                    checked={formData.permission.some(p => p.id === permission.id) || formData.permission.includes(permission.id)}  // Check if permission `id` is in `formData.permission`
                                    onChange={handlePermissionChange}  // Use separate handler for permissions
                                    className="mr-2"
                                />
                                {permission.name}
                            </label>
                        ))}
                    </div>
                </div>

                <div className="mb-4">
                    <label className="text-gray-700">Companies:</label>
                    <div className="border p-3 rounded-md mt-2 bg-gray-50">
                        {allCompanies.map(company => (
                            <label key={company.id} className="block text-gray-700">
                                <input
                                    type="checkbox"
                                    value={company.id}  // Pass only the `id` of the company
                                    checked={formData.companies.some(c => c.id === company.id) || formData.companies.includes(company.id)}  // Check if company `id` is in `formData.companies`
                                    onChange={handleCompanyChange}  // Use separate handler for companies
                                    className="mr-2"
                                />
                                {company.companyName}
                            </label>
                        ))}
                    </div>
                </div>


                {/* Remaining fields and Submit buttons */}

                <div className="mt-6 flex justify-end">
                    <button
                        type="submit"
                        className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 mr-2"
                    >
                        Save
                    </button>
                    <button
                        onClick={onCancel}
                        className="bg-gray-500 text-white px-4 py-2 rounded-md hover:bg-gray-600"
                    >
                        Cancel
                    </button>
                </div>
            </form>
        </div>
    );
};

export default EditUser;
