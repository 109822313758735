import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const BulkProductUpload = ({ toggleComponent }) => {
    const [file, setFile] = useState(null);
    const [selectedCompanies, setSelectedCompanies] = useState([]);
    const [companyOptions, setCompanyOptions] = useState([]);

    useEffect(() => {
        const fetchCompanies = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await axios.get('https://emkayindia.in/api/v1/company/all', {
                    headers: { 'Authorization': `Bearer ${token}` }
                });
                
                const options = response.data.map(company => ({
                    value: company.id,
                    label: company.companyName
                }));
                setCompanyOptions(options);
            } catch (error) {
                console.error('Error fetching companies:', error);
                toast.error('Error loading company list');
            }
        };
        
        fetchCompanies();
    }, []);

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!file) {
            toast.error('Please select a file');
            return;
        }
        if (selectedCompanies.length === 0) {
            toast.error('Please select at least one company');
            return;
        }

        const formData = new FormData();
        formData.append('file', file);
        formData.append('companyIds', JSON.stringify(selectedCompanies.map(company => company.value)));

        try {
            const token = localStorage.getItem('token');
            await axios.post('https://emkayindia.in/api/v1/product/createmany', formData, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data',
                },
            });
            toast.success('Products uploaded successfully!');
            // toggleComponent("DashBoard");
        } catch (error) {
            toast.error(`Error uploading products: ${error.response?.data?.message || 'Unknown error'}`);
        }
    };

    return (
        <form onSubmit={handleSubmit} className="p-6 bg-white rounded shadow-md max-w-lg ml-[42%] mt-20">
            <h2 className="text-xl font-semibold mb-4">Bulk Product Upload</h2>
            
            <div className="mb-4">
                <label className="block text-gray-700 mb-2">Select File:</label>
                <input
                    type="file"
                    onChange={handleFileChange}
                    className="block w-full text-sm text-gray-600 bg-gray-200 rounded border border-gray-300 cursor-pointer focus:outline-none"
                />
            </div>

            <div className="mb-4">
                <label className="block text-gray-700 mb-2">Select Companies:</label>
                <Select
                    options={companyOptions}
                    isMulti
                    value={selectedCompanies}
                    onChange={setSelectedCompanies}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder="Select companies..."
                />
            </div>

            <button
                type="submit"
                className="bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700 transition duration-300"
            >
                Upload Products
            </button>

            <ToastContainer />
        </form>
    );
};

export default BulkProductUpload;
